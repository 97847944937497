import { Injectable } from '@angular/core';

import { AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from "rxjs";

import { User } from './user';
import * as moment from 'moment';
import { serialize } from 'v8';
@Injectable({
    providedIn: 'root'
})
export class subCollectionServie {
    retarray: any = [];
    profile: User;
    recordCount = 0;
    private _userData: BehaviorSubject<User[]>;
    public userData: Observable<User[]>;
    latestEntry: any;
    queryData: any;
    constructor(
        private firestore: AngularFirestore
    ) {

    }
    //TEST SERVICE TO INSERT SUB-COLLECTIONS START
    new_SubCollection() {
        alert("Ready to add sub-collections. !!");
        let admissionNo = ""; let firstName = ""; let lastName = ""; let addedBy = ""; let addedById = ""; let dob = ""; let doj = "";
        let email = ""; let phoneNumber1 = ""; let pincode = ""; let fcmToken = ""; let mentor = ""; let modifiedById = ""; let modifiedBy = "";
        let modifiedDate = ""; let phoneNumber2 = ""; let fatherName = ""; let fatherPhone = ""; let fatherEmail = ""; let motherName = "";
        let motherPhone = ""; let address1 = ""; let address2 = ""; let address3 = ""; let address4 = ""; let adminDashboardUser = false;
        let appUser = false; let bloodGroup = ""; let userName = ""; let firebaseToMongoSync = false; let mongoToFirebaseSync = false;
        let department = ""; let designation = ""; let employeeNo = ""; let gender = "";
        let stream = ""; let stream_name = ""; let course = ""; let course_name; let level = ""; let level_name = ""; let batch = "";

        return new Promise((resolve, reject) => {
            console.log(this.latestEntry);
            if (this.latestEntry === undefined || this.latestEntry === null || !this.latestEntry) {
                this.firestore.collection('users', ref => ref
                    //   .where('userType', '==', 'student')
                    //   .where('batch', '==', '2015')
                    //   .where('gender', '==', 'male')          
                    .orderBy('firstName', 'asc')
                    .limit(10)
                ).get()
                    .forEach(res => {
                        if (res.docs && res.docs.length > 0) {
                            this.latestEntry = res.docs[res.docs.length - 1];
                            for (let doc = 0; doc < res.docs.length; doc++) {
                                let keyId = res.docs[doc].id;
                                let rec = res.docs[doc].data();
                                if (rec.admissionNo) { admissionNo = rec.admissionNo; } else { admissionNo = ""; }
                                if (rec.employeeNo) { employeeNo = rec.employeeNo; } else { employeeNo = ""; }
                                if (rec.firstName) { firstName = rec.firstName; } else { firstName = ""; }
                                if (rec.lastName) { lastName = rec.lastName; } else { lastName = ""; }
                                if (rec.gender) { gender = rec.gender; } else { gender = ""; }
                                if (rec.addedBy) { addedBy = rec.addedBy; } else { addedBy = ""; }
                                if (rec.addedById) { addedById = rec.addedById; } else { addedById = ""; }
                                if (rec.dob) { dob = rec.dob; } else { dob = ""; }
                                if (rec.doj) { doj = rec.doj; } else { doj = ""; }
                                if (rec.email) { email = rec.email; } else { email = ""; }
                                if (rec.fcmToken) { fcmToken = rec.fcmToken; } else { fcmToken = ""; }
                                if (rec.mentor) { mentor = rec.mentor; } else { mentor = ""; }
                                if (rec.modifiedById) { modifiedById = rec.modifiedById; } else { modifiedById = ""; }
                                if (rec.modifiedBy) { modifiedBy = rec.modifiedBy; } else { modifiedBy = ""; }
                                if (rec.modifiedDate) { modifiedDate = rec.modifiedDate; } else { modifiedDate = ""; }
                                if (rec.phoneNumber1) { phoneNumber1 = rec.phoneNumber1; } else { phoneNumber1 = ""; }
                                if (rec.phoneNumber2) { phoneNumber2 = rec.phoneNumber2; } else { phoneNumber2 = ""; }
                                if (rec.fatherName) { fatherName = rec.fatherName; } else { fatherName = ""; }
                                if (rec.fatherPhone) { fatherPhone = rec.fatherPhone; } else { fatherPhone = ""; }
                                if (rec.fatherEmail) { fatherEmail = rec.fatherEmail; } else { fatherEmail = ""; }
                                if (rec.motherName) { motherName = rec.motherName; } else { motherName = ""; }
                                if (rec.motherPhone) { motherPhone = rec.motherPhone; } else { motherPhone = ""; }
                                if (rec.address1) { address1 = rec.address1; } else { address1 = ""; }
                                if (rec.address2) { address2 = rec.address2; } else { address2 = ""; }
                                if (rec.address3) { address3 = rec.address3; } else { address3 = ""; }
                                if (rec.address4) { address4 = rec.address4; } else { address4 = ""; }
                                if (rec.adminDashboardUser) { adminDashboardUser = rec.adminDashboardUser; } else { adminDashboardUser = false; }
                                if (rec.appUser) { appUser = rec.appUser; } else { appUser = false; }
                                if (rec.bloodGroup) { bloodGroup = rec.bloodGroup; } else { bloodGroup = ""; }
                                if (rec.userName) { userName = rec.userName; } else { userName = ""; }
                                if (rec.firebaseToMongoSync) { firebaseToMongoSync = rec.firebaseToMongoSync; } else { firebaseToMongoSync = false; }
                                if (rec.mongoToFirebaseSync) { mongoToFirebaseSync = rec.mongoToFirebaseSync; } else { mongoToFirebaseSync = false; }
                                if (rec.department) { department = rec.department; } else { department = ""; }
                                if (rec.designation) { designation = rec.designation; } else { designation = ""; }
                                if (rec.userType && rec.userType === "student") {
                                    let insObj = {
                                        Date: rec.Date,
                                        addedBy: addedBy,
                                        addedById: addedById,
                                        admissionNo: admissionNo,
                                        batch: batch,
                                        course: course,
                                        course_name: course_name,
                                        dob: dob,
                                        doj: doj,
                                        email: email,
                                        fcmToken: fcmToken,
                                        firebaseToMongoSync: firebaseToMongoSync,
                                        firstName: firstName,
                                        gender: gender,
                                        id: rec.id,
                                        isDeleted: rec.isDeleted,
                                        lastName: lastName,
                                        level: level,
                                        level_name: level_name,
                                        mentor: mentor,
                                        modifiedBy: modifiedBy,
                                        modifiedById: modifiedById,
                                        modifiedDate: modifiedDate,
                                        mongoToFirebaseSync: mongoToFirebaseSync,
                                        orgId: rec.orgId,
                                        password: rec.password,
                                        phoneNumber1: phoneNumber1,
                                        phoneNumber2: phoneNumber2,
                                        bloodGroup: bloodGroup,
                                        fatherName: fatherName,
                                        fatherPhone: fatherPhone,
                                        fatherEmail: fatherEmail,
                                        motherName: motherName,
                                        motherPhone: motherPhone,
                                        address1: address1,
                                        address2: address2,
                                        address3: address3,
                                        address4: address4,
                                        adminDashboardUser: adminDashboardUser,
                                        appUser: appUser,
                                        stream: stream,
                                        stream_name: stream_name,
                                        userName: userName,
                                        userRole: rec.userRole,
                                        userType: rec.userType,
                                        keyId: keyId
                                    }
                                    this.insertUser(keyId, insObj, rec);
                                } else if (rec.userType && rec.userType === "faculty") {
                                    let insObj = {
                                        Date: rec.Date,
                                        addedBy: addedBy,
                                        addedById: addedById,
                                        employeeNo: employeeNo,
                                        dob: dob,
                                        doj: doj,
                                        email: email,
                                        department: department,
                                        designation: designation,
                                        fcmToken: fcmToken,
                                        firebaseToMongoSync: firebaseToMongoSync,
                                        firstName: firstName,
                                        gender: gender,
                                        id: rec.id,
                                        isDeleted: rec.isDeleted,
                                        lastName: lastName,
                                        modifiedBy: modifiedBy,
                                        modifiedById: modifiedById,
                                        modifiedDate: modifiedDate,
                                        mongoToFirebaseSync: mongoToFirebaseSync,
                                        orgId: rec.orgId,
                                        password: rec.password,
                                        phoneNumber1: phoneNumber1,
                                        phoneNumber2: phoneNumber2,
                                        bloodGroup: bloodGroup,
                                        address1: address1,
                                        address2: address2,
                                        address3: address3,
                                        address4: address4,
                                        adminDashboardUser: adminDashboardUser,
                                        appUser: appUser,
                                        userName: userName,
                                        userRole: rec.userRole,
                                        userType: rec.userType,
                                        keyId: keyId
                                    }
                                    this.insertUser(keyId, insObj, rec);
                                }

                            }
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                this.firestore.collection('users', ref => ref
                    // .where('userType', '==', 'student')
                    // .where('batch', '==', '2015')
                    // .where('gender', '==', 'male')          
                    .orderBy('firstName', 'asc')
                    .startAfter(this.latestEntry)
                    .limit(10)
                ).get()
                    .forEach(res => {
                        if (res.docs && res.docs.length > 0) {
                            this.latestEntry = res.docs[res.docs.length - 1];
                            for (let doc = 0; doc < res.docs.length; doc++) {
                                let keyId = res.docs[doc].id;
                                let rec = res.docs[doc].data();
                                if (rec.admissionNo) { admissionNo = rec.admissionNo; } else { admissionNo = ""; }
                                if (rec.employeeNo) { employeeNo = rec.employeeNo; } else { employeeNo = ""; }
                                if (rec.firstName) { firstName = rec.firstName; } else { firstName = ""; }
                                if (rec.lastName) { lastName = rec.lastName; } else { lastName = ""; }
                                if (rec.gender) { gender = rec.gender; } else { gender = ""; }
                                if (rec.addedBy) { addedBy = rec.addedBy; } else { addedBy = ""; }
                                if (rec.addedById) { addedById = rec.addedById; } else { addedById = ""; }
                                if (rec.dob) { dob = rec.dob; } else { dob = ""; }
                                if (rec.doj) { doj = rec.doj; } else { doj = ""; }
                                if (rec.email) { email = rec.email; } else { email = ""; }
                                if (rec.fcmToken) { fcmToken = rec.fcmToken; } else { fcmToken = ""; }
                                if (rec.mentor) { mentor = rec.mentor; } else { mentor = ""; }
                                if (rec.modifiedById) { modifiedById = rec.modifiedById; } else { modifiedById = ""; }
                                if (rec.modifiedBy) { modifiedBy = rec.modifiedBy; } else { modifiedBy = ""; }
                                if (rec.modifiedDate) { modifiedDate = rec.modifiedDate; } else { modifiedDate = ""; }
                                if (rec.phoneNumber1) { phoneNumber1 = rec.phoneNumber1; } else { phoneNumber1 = ""; }
                                if (rec.phoneNumber2) { phoneNumber2 = rec.phoneNumber2; } else { phoneNumber2 = ""; }
                                if (rec.fatherName) { fatherName = rec.fatherName; } else { fatherName = ""; }
                                if (rec.fatherPhone) { fatherPhone = rec.fatherPhone; } else { fatherPhone = ""; }
                                if (rec.fatherEmail) { fatherEmail = rec.fatherEmail; } else { fatherEmail = ""; }
                                if (rec.motherName) { motherName = rec.motherName; } else { motherName = ""; }
                                if (rec.motherPhone) { motherPhone = rec.motherPhone; } else { motherPhone = ""; }
                                if (rec.address1) { address1 = rec.address1; } else { address1 = ""; }
                                if (rec.address2) { address2 = rec.address2; } else { address2 = ""; }
                                if (rec.address3) { address3 = rec.address3; } else { address3 = ""; }
                                if (rec.address4) { address4 = rec.address4; } else { address4 = ""; }
                                if (rec.adminDashboardUser) { adminDashboardUser = rec.adminDashboardUser; } else { adminDashboardUser = false; }
                                if (rec.appUser) { appUser = rec.appUser; } else { appUser = false; }
                                if (rec.bloodGroup) { bloodGroup = rec.bloodGroup; } else { bloodGroup = ""; }
                                if (rec.userName) { userName = rec.userName; } else { userName = ""; }
                                if (rec.firebaseToMongoSync) { firebaseToMongoSync = rec.firebaseToMongoSync; } else { firebaseToMongoSync = false; }
                                if (rec.mongoToFirebaseSync) { mongoToFirebaseSync = rec.mongoToFirebaseSync; } else { mongoToFirebaseSync = false; }
                                if (rec.department) { department = rec.department; } else { department = ""; }
                                if (rec.designation) { designation = rec.designation; } else { designation = ""; }
                                if (rec.userType && rec.userType === "student") {
                                    let insObj = {
                                        Date: rec.Date,
                                        addedBy: addedBy,
                                        addedById: addedById,
                                        admissionNo: admissionNo,
                                        batch: batch,
                                        course: course,
                                        course_name: course_name,
                                        dob: dob,
                                        doj: doj,
                                        email: email,
                                        fcmToken: fcmToken,
                                        firebaseToMongoSync: firebaseToMongoSync,
                                        firstName: firstName,
                                        gender: gender,
                                        id: rec.id,
                                        isDeleted: rec.isDeleted,
                                        lastName: lastName,
                                        level: level,
                                        level_name: level_name,
                                        mentor: mentor,
                                        modifiedBy: modifiedBy,
                                        modifiedById: modifiedById,
                                        modifiedDate: modifiedDate,
                                        mongoToFirebaseSync: mongoToFirebaseSync,
                                        orgId: rec.orgId,
                                        password: rec.password,
                                        phoneNumber1: phoneNumber1,
                                        phoneNumber2: phoneNumber2,
                                        bloodGroup: bloodGroup,
                                        fatherName: fatherName,
                                        fatherPhone: fatherPhone,
                                        fatherEmail: fatherEmail,
                                        motherName: motherName,
                                        motherPhone: motherPhone,
                                        address1: address1,
                                        address2: address2,
                                        address3: address3,
                                        address4: address4,
                                        adminDashboardUser: adminDashboardUser,
                                        appUser: appUser,
                                        stream: stream,
                                        stream_name: stream_name,
                                        userName: userName,
                                        userRole: rec.userRole,
                                        userType: rec.userType,
                                        keyId: keyId
                                    }
                                    this.insertUser(keyId, insObj, rec);
                                } else if (rec.userType && rec.userType === "faculty") {
                                    let insObj = {
                                        Date: rec.Date,
                                        addedBy: addedBy,
                                        addedById: addedById,
                                        employeeNo: employeeNo,
                                        dob: dob,
                                        doj: doj,
                                        email: email,
                                        department: department,
                                        designation: designation,
                                        fcmToken: fcmToken,
                                        firebaseToMongoSync: firebaseToMongoSync,
                                        firstName: firstName,
                                        gender: gender,
                                        id: rec.id,
                                        isDeleted: rec.isDeleted,
                                        lastName: lastName,
                                        modifiedBy: modifiedBy,
                                        modifiedById: modifiedById,
                                        modifiedDate: modifiedDate,
                                        mongoToFirebaseSync: mongoToFirebaseSync,
                                        orgId: rec.orgId,
                                        password: rec.password,
                                        phoneNumber1: phoneNumber1,
                                        phoneNumber2: phoneNumber2,
                                        bloodGroup: bloodGroup,
                                        address1: address1,
                                        address2: address2,
                                        address3: address3,
                                        address4: address4,
                                        adminDashboardUser: adminDashboardUser,
                                        appUser: appUser,
                                        userName: userName,
                                        userRole: rec.userRole,
                                        userType: rec.userType,
                                        keyId: keyId
                                    }
                                    this.insertUser(keyId, insObj, rec);
                                }

                            }
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }

        });
    }
    //TEST SERVICE TO INSERT SUB-COLLECTIONS END
    //INSERT USER DOCUMENT
    insertUser(keyId, insObj, rec) {
        this.firestore.collection("newUsersLatest").doc(keyId).set(insObj).then(res => {
            //console.log(res);
            let UpdateData = { keyId: keyId };
            // //UPDATE KEYID
            // this.firestore.doc('newUsersLatest/' + res.id).update(UpdateData);
            console.log(rec, keyId);
            this.academicDetails(rec, keyId);
        })
            .catch(err => {
                console.log(err);
            });
    }
    //Insert academic sub-collection
    academicDetails(rec, keyId) {
        //ACADEMIC SUB-COLLECTION
        if (rec.academic && rec.academic.length > 1) {
            console.log(rec.academic);
            rec.academic.forEach((a, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(keyId)
                    .collection("academic").add(a)
                    .then(res1 => {
                        console.log(res1.id);
                        let academicKeyId = res1.id;
                        let academicUpdateData = { keyId: academicKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("academic").doc(academicKeyId).update(academicUpdateData);
                    })
                    .catch(err1 => {
                        console.log(err1);
                    });
                if (index === rec.academic.length - 1) {
                    console.log("Academic Details inserted for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                    this.eventsDetails(rec, keyId);
                }
            });
        } else {
            if (rec.userType === "student") {
                console.log("Academic Details not available for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
            }
            this.eventsDetails(rec, keyId);
        }
    }
    eventsDetails(rec, keyId) {
        //EVENTS SUB-COLLECTION
        if (rec.events && rec.events.length) {
            rec.events.forEach((e, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(keyId)
                    .collection("events").add(e)
                    .then(res2 => {
                        console.log(res2.id);
                        let eventKeyId = res2.id;
                        let eventUpdateData = { keyId: eventKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("events").doc(eventKeyId).update(eventUpdateData);
                    })
                    .catch(err2 => {
                        console.log(err2);
                    });
                if (index === rec.events.length - 1) {
                    console.log("Events Details inserted for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                    this.attendanceDetails(rec, keyId);
                }
            });
        } else {
            if (rec.userType === "student") {
                console.log("Events Details not available for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
            } else {
                console.log("Events Details not available for " + rec.employeeNo + "-" + rec.firstName + " " + rec.lastName);
            }
            this.attendanceDetails(rec, keyId);
        }
    }
    attendanceDetails(rec, keyId) {
        //ATTENDANCE SUB-COLLECTION
        if (rec.attendance && rec.attendance.length) {
            rec.attendance.forEach((at, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(keyId)
                    .collection("attendance").add(at)
                    .then(res3 => {
                        console.log(res3.id);
                        let attendanceKeyId = res3.id;
                        let attendanceUpdateData = { keyId: attendanceKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("attendance").doc(attendanceKeyId).update(attendanceUpdateData);
                    })
                    .catch(err3 => {
                        console.log(err3);
                    });
                if (index === rec.attendance.length - 1) {
                    console.log("Attendance Details inserted for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                    this.billsDetails(rec, keyId);
                }
            });
        } else {
            if (rec.userType === "student") {
                console.log("Attendance Details not available for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
            }
            this.billsDetails(rec, keyId);
        }
    }
    billsDetails(rec, keyId) {
        //BILLS SUB-COLLECTION
        if (rec.bills && rec.bills.length) {
            rec.bills.forEach((b, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(keyId)
                    .collection("bills").add(b)
                    .then(res4 => {
                        console.log(res4.id);
                        let billsKeyId = res4.id;
                        let billsUpdateData = { keyId: billsKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("bills").doc(billsKeyId).update(billsUpdateData);
                    })
                    .catch(err4 => {
                        console.log(err4);
                    });
                if (index === rec.bills.length - 1) {
                    console.log("Bills Details inserted for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                    this.fosterParentsDetails(rec, keyId);
                }
            });
        } else {
            if (rec.userType === "student") {
                console.log("Bills Details not available for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                this.fosterParentsDetails(rec, keyId);
            } else {
                this.fosterFamilyDetails(rec, keyId);
            }
        }
    }
    fosterParentsDetails(rec, keyId) {
        //fosterParents SUB-COLLECTION
        if (rec.fosterParents && rec.fosterParents.length) {
            rec.fosterParents.forEach((fp, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(rec.keyId)
                    .collection("fosterParents").add(fp)
                    .then(res5 => {
                        console.log(res5.id);
                        let fosterParentsKeyId = res5.id;
                        let fosterParentsUpdateData = { keyId: fosterParentsKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("fosterParents").doc(fosterParentsKeyId).update(fosterParentsUpdateData);
                    })
                    .catch(err5 => {
                        console.log(err5);
                    });
                if (index === rec.fosterParents.length - 1) {
                    // this.fosterParentsDetails(rec);
                    console.log("Foster Parent Details inserted for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
                }
            });
        } else {
            if (rec.userType === "student") {
                console.log("Foster Parent Details not available for " + rec.admissionNo + "-" + rec.firstName + " " + rec.lastName);
            }
        }
    }
    fosterFamilyDetails(rec, keyId) {
        //fosterFamily SUB-COLLECTION
        if (rec.fosterFamily && rec.fosterFamily.length) {
            rec.fosterFamily.forEach((fp, index) => {
                this.firestore
                    .collection("newUsersLatest").doc(rec.keyId)
                    .collection("fosterParents").add(fp)
                    .then(res5 => {
                        console.log(res5.id);
                        let fosterFamilyKeyId = res5.id;
                        let fosterFamilyUpdateData = { keyId: fosterFamilyKeyId };
                        //UPDATE KEYID
                        this.firestore.doc('newUsersLatest/' + keyId)
                            .collection("fosterFamily").doc(fosterFamilyKeyId).update(fosterFamilyUpdateData);
                    })
                    .catch(err5 => {
                        console.log(err5);
                    });
                if (index === rec.fosterFamily.length - 1) {
                    // this.fosterParentsDetails(rec);
                    console.log("Foster Family Details inserted for " + rec.employeeNo + "-" + rec.firstName + " " + rec.lastName);
                }
            });
        } else {
            console.log("Foster Family Details not available for " + rec.employeeNo + "-" + rec.firstName + " " + rec.lastName);
        }
    }

    ScheduleinsertStreamLevelCourseBatchStudents() {
        let retArr = [];
        return this.firestore.collection('users', ref => ref
            .where('userType', '==', 'student')
            .where('batch', '==', '2016')
        )
            .get()
            .forEach(res => {
                if (res.docs && res.docs.length > 0) {
                    for (let doc = 0; doc < res.docs.length; doc++) {
                        let keyId = res.docs[doc].id;
                        let rec = res.docs[doc].data();
                        //console.log(rec.admissionNo+" "+rec.firstName+" "+rec.lastName);
                        rec.events.forEach((e, index) => {
                            e.groups[0].sessions.forEach((s, index1) => {
                                //console.log(s);
                                s.stream = [{ streamId: 'g9q64iWEQhcCRNwwJ' }];
                                s.level = [{ streamId: 'g9q64iWEQhcCRNwwJ', levelId: 'WA9vGeQLLZMXBiZha' }];
                                s.course = [{ streamId: 'g9q64iWEQhcCRNwwJ', levelId: 'WA9vGeQLLZMXBiZha', courseId: 'vHjpaNdCoBrwCKZ7h' }];
                                s.batch = [];
                                //console.log("Group Length: "+s.group.length);
                                s.group.forEach((g, index2) => {
                                    //console.log(g);
                                    this.firestore.collection('groups', ref => ref
                                        .where('groupId', '==', g.groupId))
                                        .get()
                                        .forEach(gr => {
                                            //console.log(gr);
                                            if (gr.docs && gr.docs.length > 0) {
                                                for (let doc1 = 0; doc1 < gr.docs.length; doc1++) {
                                                    let grp = gr.docs[doc1].data();
                                                    s.batch.push({ "batchId": grp.batch });
                                                }
                                            }
                                        });
                                    if (index === rec.events.length - 1
                                        && index1 === e.groups[0].sessions.length - 1
                                        && index2 === s.group.length - 1) {
                                        console.log(index);
                                        console.log(rec);
                                        let updateData = { "events": rec.events };
                                        this.firestore.collection("users").doc(keyId).update(updateData);
                                    }
                                });
                            });
                        });
                    }
                }
            });
    }

    read_users_New() {
        let retArr = [];
        return this.firestore.collection('newUsers', ref => ref.where('userType', '==', 'student'))
            .get()
            .forEach(res => {
                if (res.docs && res.docs.length > 0) {
                    for (let doc = 0; doc < res.docs.length; doc++) {
                        let keyId = res.docs[doc].id;
                        let rec = res.docs[doc].data();

                        retArr.push(rec);
                        if (doc === res.docs.length - 1) {
                            console.log(retArr);
                        }
                    }
                }
            });
    }
    read_users_new_events() {
        let retArr = [];
        return this.firestore.collection('newUsers', ref => ref.where('userType', '==', 'student'))
            .get()
            .forEach(res => {
                if (res.docs && res.docs.length > 0) {
                    for (let doc = 0; doc < res.docs.length; doc++) {
                        let keyId = res.docs[doc].id;
                        let rec = res.docs[doc].data();

                        //THIS WILL FETCH ALL THE EVENTS OF ALL THE USERS
                        this.firestore.collection('newUsers').doc(keyId)
                            .collection('events')
                            .get()
                            .forEach(res1 => {
                                console.log(doc + " : " + keyId);
                                for (let doc1 = 0; doc1 < res1.docs.length; doc1++) {
                                    let keyId1 = res1.docs[doc1].id;
                                    let userId = keyId;
                                    let rec1 = res1.docs[doc1].data();
                                    rec1.keyId = keyId1;
                                    rec1.userId = userId;
                                    retArr.push(rec1);
                                    if (doc1 === res1.docs.length - 1) {
                                        console.log(retArr);
                                    }
                                }
                            });
                    }
                }
            });
    }


}
