// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCWdTDgGSkwp5SEysmqyNdK-NCsazizgFg",
    authDomain: "testcap-103a1.firebaseapp.com",
    databaseURL: "https://testcap-103a1.firebaseio.com",
    projectId: "testcap-103a1",
    storageBucket: "testcap-103a1.appspot.com",
    messagingSenderId: "379087371105",
    appId: "1:379087371105:web:7d04ae395b8c06082be320",
    measurementId: "G-4RDXS9DH43"
 }
};
