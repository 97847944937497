import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlashCardComponent } from './flash-card/flash-card.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';



@NgModule({
  declarations: [FlashCardComponent, BillDetailsComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [FlashCardComponent, BillDetailsComponent],
  entryComponents:[]
})
export class ComponentsModule { }
