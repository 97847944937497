import { Injectable } from "@angular/core";
import {
  Platform,
  LoadingController,
  NavController,
  ToastController
} from "@ionic/angular";

import { Storage } from "@ionic/storage";
import { MsalService } from "@azure/msal-angular";
import { User } from "./user";
import { OAuthSettings } from "../oauth";
import { StudentService } from "./../services/student.service";
import { BehaviorSubject } from "rxjs";
import {
  MSAdal,
  AuthenticationContext,
  AuthenticationResult,
} from "@ionic-native/ms-adal/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  myToken: String;
  displayMenu: any = [];
  loginMessage = "";
  public appPagesadmin = [
    {
      title: "Home",
      url: "/home",
      searchTerm: "",
      icon: "home",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Schedule",
      url: "/schedule",
      searchTerm: "",
      icon: "calendar",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "People",
      url: "/people",
      searchTerm: "allStudents",
      icon: "contacts",
      colsize: 4,
      cardcolor: "tertiary",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"],
      subPages: [
        { title: 'All Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
        // { title: 'MBBS', url: '/mbbs', icon: "contacts",
        //   mbbsPages: [
        //     { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
        //     { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
        //     { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
        //     { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
        //     { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
        //     { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
        //     { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
        //   ],
        // },
        { title: 'Faculty', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
      ]
    },
    {
      title: "Bills",
      url: "/list",
      searchTerm: "",
      icon: "filing",
      colsize: 4,
      cardcolor: "warning",
      onhome: true,
      roles: ["student"],
    },
    {
      title: "My Mentees",
      url: "/my-mentees",
      searchTerm: "",
      icon: "contacts",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["admin", "faculty"]
    },
    {
      title: "Academic Details",
      url: "/attendance-marks",
      searchTerm: "",
      icon: "book",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["student"]
    },
    // {
    //   title: "Attendance",
    //   url: "/attendance",
    //   searchTerm: "",
    //   icon: "people",
    //   colsize: 4,
    //   cardcolor: "primary",
    //   onhome: true,
    // },
    {
      title: "Notifications",
      url: "/alerts",
      searchTerm: "",
      icon: "alert",
      colsize: 4,
      cardcolor: "danger",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "UG Research",
      url: "/ugresearch",
      searchTerm: "",
      colsize: 4,
      icon: "bookmarks",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Profile",
      url: "/profile",
      searchTerm: "",
      icon: "person",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Logout",
      url: "/logout",
      searchTerm: "",
      icon: "log-out",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
  ];
  // public appPagesstudent = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   {
  //     title: "Academics",
  //     url: "/my-academics",
  //     searchTerm: "",
  //     icon: "book",
  //     colsize: 4,
  //     cardcolor: "secondary",
  //     onhome: true,
  //   },
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'All Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       {
  //         title: 'MBBS', url: '/mbbs', icon: "contacts",
  //         mbbsPages: [
  //           { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //           { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //           { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //           { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //           { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //           { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //           { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //         ],
  //       },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "Bills",
  //     url: "/list",
  //     searchTerm: "",
  //     icon: "filing",
  //     colsize: 4,
  //     cardcolor: "warning",
  //     onhome: true,
  //   },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];
  // public appPagesfaculty = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'All Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       {
  //         title: 'MBBS', url: '/mbbs', icon: "contacts",
  //         mbbsPages: [
  //           { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //           { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //           { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //           { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //           { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //           { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //           { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //         ],
  //       },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "My Mentees",
  //     url: "/my-mentees",
  //     searchTerm: "",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   // {
  //   //   title: "Attendance",
  //   //   url: "/attendance",
  //   //   searchTerm: "",
  //   //   icon: "people",
  //   //   colsize: 4,
  //   //   cardcolor: "primary",
  //   //   onhome: true,
  //   // },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];

  // public appPagessecretary = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'All Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       {
  //         title: 'MBBS', url: '/mbbs', icon: "contacts",
  //         mbbsPages: [
  //           { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //           { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //           { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //           { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //           { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //           { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //           { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //         ],
  //       },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];
  public authenticated: boolean;
  public user: User;
  //public oSettings = OAuthSettings;
  public student: any = [];
  private login: boolean = false;
  private loginSubject$ = new BehaviorSubject<boolean>(this.login);
  loginChanged$ = this.loginSubject$.asObservable();
  public authPerson$ = new BehaviorSubject<object>(this.student);
  authPersonC$ = this.authPerson$.asObservable();

  private menuSubject$ = new BehaviorSubject(this.displayMenu);
  menuChanged$ = this.menuSubject$.asObservable();

  AuthenticationResponse: any = {};
  AuthenticationError: any = {};
  //loginMessage: string = "";
  constructor(
    public plt: Platform,
    public loadingCtrl: LoadingController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    private storage: Storage,
    private msAdal: MSAdal,
    private iab: InAppBrowser,
    private msalService: MsalService,
    private studentService: StudentService,
    private firebase: FirebaseX
    //public events: Events
  ) {
    // this.authenticated = msalService.getAccount() != null;
    // console.log("Auth service: authenticated", this.authenticated);

    // this.msalService.handleRedirectCallback((error, response) => {
    //   console.log("Calling handleRedirect from auth.svc constructor");
    //   // handle redirect response or error
    //   if (error) {
    //     console.log(error);
    //   }
    //   if (response) {
    //     console.log("handle redirect: ", JSON.stringify(response));
    //     console.log("navigating to /home");
    //     console.log(
    //       "get_account: ",
    //       JSON.stringify(this.msalService.getAccount())
    //     );
    //     var loggedInUserEmail = response.account.userName;
    //     this.storage.set("loggedInUserEmail", loggedInUserEmail);
    //     var msg = "Welcome " + response.account.userName;

    //     this.studentService
    //       .read_student_by_email(loggedInUserEmail)
    //       .subscribe((data) => {
    //         this.storage.set("loggedUser", data[0]);
    //         this.student = data[0];
    //         this.authPerson$.next(this.student);
    //         this.presentToast(msg);
    //         this.navCtrl.navigateRoot("/home");
    //       });
    //   }
    // });
  }

  updateLogin() {
    this.login = !this.login;
    //this.loginSubject$.next(this.login);
  }

  async presentToast(msg) {
    //this.presentLoading();

    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 5000,
    });
    toast.present();
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      spinner: null,
      duration: 5000,
      message: "Please wait...",
      translucent: true,
      cssClass: "custom-class custom-loading",
    });
    return await loading.present();
  }
  async autoLogin() {
    //alert('Trying autoLogin');

    this.storage.get("loggedUser").then((v) => {
      //console.log("Platforms: ", this.plt.platforms());
      if (v) {
        this.student = v;
        //console.log("LoggedUser: ", v.userName);
        OAuthSettings.loginHint = v.userName;
        if (this.plt.is("android") || this.plt.is("ios")) {
          //console.log('if android or iOS');          

          let authContext: AuthenticationContext = this.msAdal.createAuthenticationContext(
            "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1"
          );
          this.storage.get("authUserid").then((authId) => {
            //console.log('In autoLogin, Login Authorization User Id: ' + authId);
            authContext
              .acquireTokenSilentAsync(
                "https://graph.microsoft.com",
                "96ca51b4-458f-45d5-847d-bcb2d31b4670",
                ""
              )
              .then((authResponse: AuthenticationResult) => {
                this.AuthenticationResponse = authResponse;
                //console.log("Service login auth response: " + JSON.stringify(this.AuthenticationResponse));
                this.storage.set(
                  "loginResponse",
                  JSON.stringify(this.AuthenticationResponse)
                );
                this.storage.set(
                  "authUserid",
                  this.AuthenticationResponse.userInfo.userId
                );
                this.storage.set(
                  "loginHint",
                  this.AuthenticationResponse.userInfo.uniqueId
                );

                this.authPerson$.next(this.student);
                //SIDE MENU ITEMS FOR THE USER ROLE
                this.appPagesadmin.forEach((menu, mindex) => {
                  menu.roles.forEach((roles, mindex1) => {
                    if (this.student.userRole) {
                      this.student.userRole.forEach((role, rindex) => {
                        if (roles === role) {
                          this.displayMenu.push(menu);
                        } else {
                          //
                        }
                        if (mindex === this.appPagesadmin.length - 1 && mindex1 === menu.roles.length - 1 && rindex === this.student.userRole.length - 1) {
                          let newArray = [];
                          if (this.displayMenu && this.displayMenu.length > 0) {
                            let uniqueObject = {};
                            for (let i in this.displayMenu) {
                              let objTitle = this.displayMenu[i].title;
                              uniqueObject[objTitle] = this.displayMenu[i];
                            }
                            for (let i in uniqueObject) {
                              newArray.push(uniqueObject[i]);
                            }
                            console.log(newArray);
                            this.displayMenu = newArray;
                            this.storage.set("displayMenu", this.displayMenu);
                            this.menuSubject$.next(this.displayMenu);
                          }

                        }
                      });
                    }
                  });

                });
                //Fetch Mobile Token
                this.firebase.onTokenRefresh()
                  .subscribe((token: string) => {
                    //alert('Got a new token: '+token); 
                    this.myToken = token;
                    this.storage.set('fcmToken', token);
                    // this.student.fcmToken = this.myToken;
                    // this.student.firebaseToMongoSync = true;
                    // this.student.mongoToFirebaseSync = false;
                    let updateStudentData = {
                      fcmToken: this.myToken,
                      firebaseToMongoSync: true,
                      mongoToFirebaseSync: false
                    };
                    setTimeout(() => {
                      this.studentService.update_mobileToken(this.student.keyId, updateStudentData);
                      this.storage.set('appPages', this.appPagesadmin).then(res => {
                        //loading.dismiss();
                        this.navCtrl.navigateRoot("/home");
                      });
                    }, 100);
                  });
                this.firebase.onMessageReceived()
                  .subscribe(data => { console.log('You received a notification from CAP app: ' + JSON.stringify(data)); });
                //
                this.firebase.getToken()
                  .then((token: string) => {
                    //alert('Got a new token: '+token); 
                    this.myToken = token;
                    this.storage.set('fcmToken', token);
                    // this.student.fcmToken = this.myToken;
                    // this.student.firebaseToMongoSync = true;
                    // this.student.mongoToFirebaseSync = false;
                    let updateStudentData = {
                      fcmToken: this.myToken,
                      firebaseToMongoSync: true,
                      mongoToFirebaseSync: false
                    };
                    setTimeout(() => {
                      this.studentService.update_mobileToken(this.student.keyId, updateStudentData);
                      this.storage.set('appPages', this.appPagesadmin).then(res => {
                        //loading.dismiss();
                        this.navCtrl.navigateRoot("/home");
                      });

                    }, 100);
                  });


              })
              .catch((e: any) => {
                this.AuthenticationError = e;
              });
            //this.loginUser();
          });
        } else {
          //console.log('if desktop');
          this.msalService
            .acquireTokenSilent(OAuthSettings)
            .then((result) => {
              //console.log(JSON.stringify(result));
              //alert(JSON.stringify(result));
              this.storage.set("authenticationResponse", result);
              //this.navCtrl.navigateRoot("/home");
              this.authPerson$.next(this.student);
              //SIDE MENU ITEMS FOR THE USER ROLE
              this.appPagesadmin.forEach((menu, mindex) => {
                menu.roles.forEach((roles, mindex1) => {
                  if (this.student.userRole) {
                    this.student.userRole.forEach((role, rindex) => {
                      if (roles === role) {
                        this.displayMenu.push(menu);
                      } else {
                        //
                      }
                      if (mindex === this.appPagesadmin.length - 1 && mindex1 === menu.roles.length - 1 && rindex === this.student.userRole.length - 1) {
                        let newArray = [];
                        if (this.displayMenu && this.displayMenu.length > 0) {
                          let uniqueObject = {};
                          for (let i in this.displayMenu) {
                            let objTitle = this.displayMenu[i].title;
                            uniqueObject[objTitle] = this.displayMenu[i];
                          }
                          for (let i in uniqueObject) {
                            newArray.push(uniqueObject[i]);
                          }
                          //console.log(newArray);
                          this.displayMenu = newArray;
                          this.storage.set("displayMenu", this.displayMenu);
                          this.menuSubject$.next(this.displayMenu);
                        }
                      }
                    });
                  }
                });

              });
              this.storage.set('appPages', this.appPagesadmin).then(res => {
                //loading.dismiss();
                this.navCtrl.navigateRoot("/home");
              });

            })
            .catch((reason) => {
              console.log(
                "In autoLogin, Get token failed 1 " + JSON.stringify(reason)
              );
            });
        }
      } else {
        //
      }
    });
  }

  async microsoftLogin() {
    //alert("MS");
    //console.log("Attempting microsoftLogin");

    var loginRequest = {
      scopes: [
        "openid",
        "profile",
        "user.Read",
        "Mail.Send",
        "Mail.Read",
        "Calendars.Read"
      ], // optional Array<string>
    };
    let userData: any;
    if (this.plt.is("android") || this.plt.is("ios")) {
      let loading = await this.loadingCtrl.create({
        message: 'Logging in...'
      });
      await loading.present();
      //console.log("in microsoftLogin: ", this.plt.platforms());
      //this.msalService.loginRedirect(loginRequest);
      this.loginMessage = "Kindly wait while we are logging you in..";
      // "96ca51b4-458f-45d5-847d-bcb2d31b4670",
      // StudentHub ^^^^^
      // 08d4537e-36e3-42c4-8b64-f8f8e39ad2b7
      // CAPDash ^^^^^
      // msauth.in.edu.cmcvellore.cap://auth

      let authContext: AuthenticationContext = this.msAdal.createAuthenticationContext(
        "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1"
      );
      authContext
        .acquireTokenAsync(
          "https://graph.microsoft.com",
          "96ca51b4-458f-45d5-847d-bcb2d31b4670",
          "http://localhost:30662",
          "",
          ""
        )
        .then((authResponse: AuthenticationResult) => {
          this.AuthenticationResponse = authResponse;
          //alert("Microsoft login auth response: "+JSON.stringify(this.AuthenticationResponse));
          this.storage.set(
            "loginResponse",
            JSON.stringify(this.AuthenticationResponse)
          );
          //console.log('microsoftLogin loginResponse: ', JSON.stringify(this.AuthenticationResponse));
          this.storage.set(
            "authUserid",
            this.AuthenticationResponse.userInfo.userId
          );
          this.storage.set(
            "loginHint",
            this.AuthenticationResponse.userInfo.uniqueId
          );
          var studentEmail = authResponse.userInfo.uniqueId;
          this.storage.set("email", studentEmail);
          var msg = "Welcome " + authResponse.userInfo.displayableId;
          this.loginMessage = "Welcome " + authResponse.userInfo.displayableId;
          //this.presentToast(msg);
          this.studentService
            .read_student_by_email(studentEmail)
            .subscribe((data) => {
              let id = data[0];
              if (data[0]) {
                //alert('MS IN USER: '+id);
                this.storage.set("loggedUser", data[0]).then(res => {
                  this.student = data[0];
                  //this.student.keyId = id;
                  //SIDE MENU ITEMS FOR THE USER ROLE
                  this.appPagesadmin.forEach((menu, mindex) => {
                    menu.roles.forEach((roles, mindex1) => {
                      if (this.student.userRole) {
                        this.student.userRole.forEach((role, rindex) => {
                          if (roles === role) {
                            this.displayMenu.push(menu);
                          } else {
                            //
                          }
                          if (mindex === this.appPagesadmin.length - 1 && mindex1 === menu.roles.length - 1 && rindex === this.student.userRole.length - 1) {
                            let newArray = [];
                            if (this.displayMenu && this.displayMenu.length > 0) {
                              let uniqueObject = {};
                              for (let i in this.displayMenu) {
                                let objTitle = this.displayMenu[i].title;
                                uniqueObject[objTitle] = this.displayMenu[i];
                              }
                              for (let i in uniqueObject) {
                                newArray.push(uniqueObject[i]);
                              }
                              console.log(newArray);
                              this.displayMenu = newArray;
                              this.storage.set("displayMenu", this.displayMenu);
                              this.menuSubject$.next(this.displayMenu);
                            }
                          }
                        });
                      }
                    });

                  });
                  //this.storage.set('appPages', this.appPagesstudent);
                  //this.authPerson$.next(this.student);
                  this.authPerson$.next(this.student);
                  this.presentToast(msg);
                  //Fetch Mobile Token

                  this.firebase.getToken().then(token => {
                    this.myToken = token;

                    this.storage.set('fcmToken', token);
                    let updateStudentData = {
                      fcmToken: this.myToken,
                      firebaseToMongoSync: true,
                      mongoToFirebaseSync: false
                    };

                    setTimeout(() => {
                      this.studentService.update_mobileToken(this.student.keyId, updateStudentData);
                      this.storage.set('appPages', this.appPagesadmin).then(res => {
                        loading.dismiss();
                        this.storage.set("displayMenu", this.displayMenu);
                        this.navCtrl.navigateRoot("/home");
                      });
                    }, 100);

                  })
                    .catch(err => console.log("Push Token Error: " + err));

                  this.firebase.onTokenRefresh()
                    .subscribe((token: string) => {
                      console.log('Got a new token: ' + token);
                      this.myToken = token;
                      this.storage.set('fcmToken', token);
                      let updateStudentData = {
                        fcmToken: this.myToken,
                        firebaseToMongoSync: true,
                        mongoToFirebaseSync: false
                      };
                      setTimeout(() => {
                        this.studentService.update_mobileToken(this.student.keyId, updateStudentData);
                        this.storage.set('appPages', this.appPagesadmin).then(res => {
                          this.navCtrl.navigateRoot("/home");
                        });
                      }, 100);
                    });
                  this.firebase.onMessageReceived()
                    .subscribe(data => { console.log('You received a notification from CAP app: ' + JSON.stringify(data)); });
                });
              } else {
                loading.dismiss();
                this.presentToast("Email address is not registered in CAP. Kindly check.");
              }

            });
        })
        .catch((e: any) => {
          this.AuthenticationError = e;
          this.loginMessage = "";
          var msg = "Authentication failed. Kindly contact the Admin. " + e;
          //alert(JSON.stringify(this.AuthenticationError));
          this.presentToast(msg);
        });
    } else {
      let loading = await this.loadingCtrl.create({
        message: 'Logging in...'
      });
      await loading.present();
      this.msalService
        .loginPopup(loginRequest)
        .then((response) => {
          // handle response
          // console.log(JSON.stringify(response.account));
          // console.log(response.account.userName);
          this.presentToast(msg);
          var loggedInUserEmail = response.account.userName;
          this.storage.set("loggedInUserEmail", loggedInUserEmail);
          var msg = "Welcome " + response.account.userName;

          this.studentService
            .read_student_by_email(loggedInUserEmail)
            .subscribe(data => {
              console.log(data);
              if (data[0]) {
                this.student = data[0];
                this.storage.set("loggedUser", this.student);
                //SIDE MENU ITEMS FOR THE USER ROLE
                this.appPagesadmin.forEach((menu, mindex) => {
                  menu.roles.forEach((roles, mindex1) => {
                    if (this.student.userRole) {
                      this.student.userRole.forEach((role, rindex) => {
                        if (roles === role) {
                          this.displayMenu.push(menu);
                          //console.log(this.displayMenu);
                        } else {
                          //
                        }
                        if (mindex === this.appPagesadmin.length - 1 && mindex1 === menu.roles.length - 1 && rindex === this.student.userRole.length - 1) {
                          let newArray = [];
                          if (this.displayMenu && this.displayMenu.length > 0) {
                            let uniqueObject = {};
                            for (let i in this.displayMenu) {
                              let objTitle = this.displayMenu[i].title;
                              uniqueObject[objTitle] = this.displayMenu[i];
                            }
                            for (let i in uniqueObject) {
                              newArray.push(uniqueObject[i]);
                            }
                            //console.log(newArray);
                            this.displayMenu = newArray;
                            this.storage.set("displayMenu", this.displayMenu);
                            this.menuSubject$.next(this.displayMenu);
                          }

                        }
                      });
                    }
                  });

                });
                this.student = data[0];
                this.authPerson$.next(this.student);
                this.presentToast(msg);
                this.storage.set('appPages', this.appPagesadmin).then(res => {
                  loading.dismiss();
                  this.storage.set("displayMenu", this.displayMenu);
                  this.navCtrl.navigateRoot("/home");
                });
              } else {
                loading.dismiss();
                this.presentToast("Email address is not registered in CAP. Kindly check.");
              }


            });
        })
        .catch((err) => {
          // handle error
          console.log(JSON.stringify(err));
          if (err.errorCode === "user_cancelled") {
            this.presentToast("The login process is cancelled by the user.");
          }
          loading.dismiss();
        });
    }
  }

  microsoftLogout() {
    // Step1: clear cache
    console.log("logged out");
    this.storage.remove("loggedInUserEmail");
    this.storage.remove("loggedUser");
    this.storage.clear().then(res => {
      if (this.plt.is('android') || this.plt.is('ios')) {
        var requestUrl = "https://login.windows.net/4d80bca5-ffd0-416d-9d68-210f85db0ed1/oauth2/logout";
        const browserRef = this.iab.create(requestUrl, '_blank', 'location=yes');
        browserRef.on("loadstop").subscribe((event: any) => {
          browserRef.close();
          this.navCtrl.navigateRoot("/login");
        });
      } else {
        this.storage.remove("loggedInUserEmail");
        this.storage.remove("loggedUser");
        this.storage.clear().then(res => {
          this.msalService.logout();
        });
      }
    }).catch(err => {
      console.log(JSON.stringify(err));
    });
  }

  // Silently request an access token
  async getAccessToken1(request) {
    let result = await this.msalService
      .acquireTokenSilent(request)
      .then(function (accessToken) {
        return accessToken;
      })
      .catch((reason) => {
        console.log("acquireTokenSilent failed:", reason);
      });
    return result;
  }
  // Silently request an access token
  async getAccessToken(request): Promise<any> {
    let result = await this.msalService
      .acquireTokenSilent(request)
      .catch((reason) => {
        console.log(
          "MSLogout, Get token failed",
          JSON.stringify(reason, null, 2)
        );
      });

    return result;
  }

  async getAccount(): Promise<any> {
    let result = await this.msalService.getAccount();

    return result;
  }

  // async getAccessToken(request): Promise<string> {

  //   return await this.msalService.acquireTokenSilent(request)
  //     .catch((reason) => {
  //       console.log('Get token failed', JSON.stringify(reason, null, 2));
  //     });

  // }


}
