import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.page.html',
  styleUrls: ['./bill-details.page.scss'],
})
export class BillDetailsPage implements OnInit {
  @Input() bill: any;
  @Input() billDetails: any;
  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }
  detailsDismiss(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
