import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'my-academics',
    loadChildren: () => import('./my-academics/my-academics.module').then( m => m.MyAcademicsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'allstudents',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'allfaculties',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2014',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2015',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2016',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2017',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2018',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2019',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'all2020',
    loadChildren: () => import('./people/people.module').then( m => m.PeoplePageModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./alerts/alerts.module').then( m => m.AlertsPageModule)
  },
  {
    path: 'ugresearch',
    loadChildren: () => import('./ugresearch/ugresearch.module').then( m => m.UgresearchPageModule)
  },
  {
    path: 'resource-details',
    loadChildren: () => import('./resource-details/resource-details.module').then( m => m.ResourceDetailsPageModule)
  },
  {
    path: 'team-research',
    loadChildren: () => import('./team-research/team-research.module').then( m => m.TeamResearchPageModule)
  },
  {
    path: 'my-mentees',
    loadChildren: () => import('./my-mentees/my-mentees.module').then( m => m.MyMenteesPageModule)
  },
  {
    path: 'mentee-details',
    loadChildren: () => import('./mentee-details/mentee-details.module').then( m => m.MenteeDetailsPageModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then( m => m.AttendancePageModule)
  },
  {
    path: 'event-details',
    loadChildren: () => import('./event-details/event-details.module').then( m => m.EventDetailsPageModule)
  },
  {
    path: 'academic-details',
    loadChildren: () => import('./academic-details/academic-details.module').then( m => m.AcademicDetailsPageModule)
  },
  {
    path: 'bill-details',
    loadChildren: () => import('./bill-details/bill-details.module').then( m => m.BillDetailsPageModule)
  },  {
    path: 'new-event',
    loadChildren: () => import('./new-event/new-event.module').then( m => m.NewEventPageModule)
  },
  {
    path: 'attendance-marks',
    loadChildren: () => import('./attendance-marks/attendance-marks.module').then( m => m.AttendanceMarksPageModule)
  },
  {
    path: 'my-academic-details',
    loadChildren: () => import('./my-academic-details/my-academic-details.module').then( m => m.MyAcademicDetailsPageModule)
  },



  // {
  //   path: 'academics',
  //   loadChildren: () => import('./academics/academics.module').then( m => m.AcademicsPageModule)
  // },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
