import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from "rxjs";

import { User } from './user';
import * as moment from 'moment';
import { serialize } from 'v8';
@Injectable({
  providedIn: 'root'
})
export class StudentService {
  retarray: any = [];
  profile: User;
  recordCount = 0;
  private _userData: BehaviorSubject<User[]>;
  public userData: Observable<User[]>;
  latestEntry: any;
  latestStreamEntry: any;
  latestLevelEntry: any;
  latestCourseEntry: any;
  latestBatchEntry: any;
  latestDepEntry: any;
  latestDesEntry: any;
  queryData: any;
  constructor(
    public firestore: AngularFirestore
  ) {

  }
  // delete_users() {
  //   let retArr = [];
  //   return this.firestore.collection('users', ref => ref
  //   .where('userType', '==', 'student')
  //   .where('batch', '==', '2019')
  //   //.where('gender', '==', 'male')
  //   .limit(20)
  //   )
  //     .get()
  //     .forEach(res => {
  //       if (res.docs && res.docs.length > 0) {
  //         for (let doc = 0; doc < res.docs.length; doc++) {
  //           let keyId = res.docs[doc].id;
  //           this.firestore.collection('users').doc(keyId).delete()
  //           .then(res => {
  //             console.log(res);
  //           })
  //           .catch(err => {
  //             console.log(err);
  //           });
  //         }
  //       }
  //     });
  // }
  read_users_New() {
    let retArr = [];
    return this.firestore.collection('newUsers', ref => ref.where('userType', '==', 'student'))
      .get()
      .forEach(res => {
        if (res.docs && res.docs.length > 0) {
          for (let doc = 0; doc < res.docs.length; doc++) {
            let keyId = res.docs[doc].id;
            let rec = res.docs[doc].data();

            retArr.push(rec);
            if (doc === res.docs.length - 1) {
              console.log(retArr);
            }
          }
        }
      });
  }

  read_users_new_events() {
    let retArr = [];
    return this.firestore.collection('newUsers', ref => ref.where('userType', '==', 'student'))
      .get()
      .forEach(res => {
        if (res.docs && res.docs.length > 0) {
          for (let doc = 0; doc < res.docs.length; doc++) {
            let keyId = res.docs[doc].id;
            let rec = res.docs[doc].data();

            //THIS WILL FETCH ALL THE EVENTS OF ALL THE USERS
            this.firestore.collection('newUsers').doc(keyId)
              .collection('events')
              .get()
              .forEach(res1 => {
                console.log(doc + " : " + keyId);
                for (let doc1 = 0; doc1 < res1.docs.length; doc1++) {
                  let keyId1 = res1.docs[doc1].id;
                  let userId = keyId;
                  let rec1 = res1.docs[doc1].data();
                  rec1.keyId = keyId1;
                  rec1.userId = userId;
                  retArr.push(rec1);
                  if (doc1 === res1.docs.length - 1) {
                    console.log(retArr);
                  }
                }
              });
          }
        }
      });
  }
  create_NewStudent(record) {
    return this.firestore.collection('users').add(record);
  }
  create_NewAttendance(collection, record) {
    let returnUniqueID;
    return this.firestore.collection(collection).add(record).then(res => {
      console.log(res.id);
      returnUniqueID = res.id;
      //record.keyId = returnUniqueID;  
      //record.attendanceId = returnUniqueID;    
      //this.firestore.doc(collection+'/' + record.keyId).update(record);
      return returnUniqueID;
    })
      .catch(err => {
        console.log(err);
        returnUniqueID = "Error marking attendance.";
        return returnUniqueID;
      });
  }
  create_NewRecord(collection, record) {
    let returnUniqueID;
    return this.firestore.collection(collection).add(record).then(res => {
      //console.log(res.id);
      returnUniqueID = res.id;
      record.keyId = returnUniqueID;
      //this.firestore.doc(collection + '/' + record.keyId).update(record);
      return returnUniqueID;
    })
      .catch(err => {
        console.log(err);
        returnUniqueID = err;
        return returnUniqueID;
      });
  }

  read_Students() {
    let retArr = [];
    return this.firestore.collection('users', ref => ref.where('userType', '==', 'faculty'))
      .get()
      .forEach(res => {
        if (res.docs && res.docs.length > 0) {
          for (let doc = 0; doc < res.docs.length; doc++) {
            let keyId = res.docs[doc].id;
            let rec = res.docs[doc].data();
            retArr.push(rec);
            if (doc === res.docs.length - 1) {
              console.log(JSON.stringify(retArr));
            }
          }
        }
      });
  }
  async update_key_id1(result) {
    for (let a = 0; a < result.docs.length; a++) {
      this.recordCount = this.recordCount + 1;
      let updateId = result.docs[a].id;
      //let updateRecord = result.docs[a].data();
      let rec = {
        keyId: updateId,
        firebaseToMongoSync: true,
        mongoToFirebaseSync: false
      };
      console.log(rec);
      //updateRecord.keyId = "";
      // if(updateId === "dgiZwEywcg8MMP8yIUwa"){
      //   console.log(updateRecord);
      // }
      //if(updateRecord.admissionNo === "Z001"){
      //updateRecord.firebaseToMongoSync = false;
      ///updateRecord.mongoToFirebaseSync = true;
      //updateRecord.avatar = "https://s3.amazonaws.com/img.studenthub.in/student/ztest_avatar.jpg";
      await this.firestore.doc('users/' + updateId).update(rec).then(res => {
        console.log(a + ": " + res);
        console.log(a + ': Updated ' + updateId);
      })
        .catch(err => {
          console.log(err);
        });
      this.retarray.push(updateId);
      //}
      if (a === result.docs.length - 1) {
        return this.retarray;
      }
    }
  }
  async spliceSessions() {
    this.recordCount = 0;
    this.firestore.collection('users', ref => ref
      .where('employeeNo', '==', 'Z12346')
    ).get().forEach(result => {
      if (result.docs && result.docs.length > 0) {
        for (let d = 0; d < result.docs.length; d++) {
          let recordId = result.docs[d].id;
          let record = result.docs[d].data();
          console.log(record);
          if (record.events && record.events.length > 0) {
            for (let e = 0; e < record.events.length; e++) {
              //console.log(e+" "+JSON.stringify(result.docs[d].data().events[e]));
              if (record.events[e].groups[0]['sessions'] && record.events[e].groups[0]['sessions'].length > 0) {
                for (let s = 0; s < record.events[e].groups[0]['sessions'].length; s++) {
                  if (record.events[e].groups[0]['sessions'][s].id === "TS") {
                    console.log(record.events[e].groups[0]['sessions'][s]);
                    record.events[e].groups[0].sessions.splice(s, 1);
                    console.log(record.events[e].groups[0]['sessions'][s]);
                  }
                }
              }
              if (e === record.events.length - 1) {
                let updateRecord = {
                  events: record.events
                };
                console.log(updateRecord);
                this.update_record("users", recordId, updateRecord);
              }
            }
          }
        }
      }
    });
    //});
  }
  async update_key_id() {
    this.recordCount = 0;
    return await new Promise((resolve, reject) => {
      //this.firestore.collection('serviceLearning').get().forEach(result => {
      this.firestore.collection('users', ref => ref
        .where('userType', '==', 'student')
        .where('batch', '==', '2016')
        //.where('gender', '==', 'female')
      ).get().forEach(result => {
        if (result.docs && result.docs.length > 0) {
          this.update_key_id1(result);
        }
      });
    });
  }
  read_student_by_id() {
    // console.log('student service ' + email);
    // console.log(this.firestore.collection('Students'));
    // return this.firestore.collection('Students', ref => ref.where('userName', '==', email)).snapshotChanges();
    let student = this.firestore.collection('users', ref => ref.where('employeeNo', '==', '28645').limit(1)).snapshotChanges();
    return student;
  }
  read_student_by_admissionNo(no) {
    // console.log('student service ' + email);
    // console.log(this.firestore.collection('Students'));
    // return this.firestore.collection('Students', ref => ref.where('userName', '==', email)).snapshotChanges();
    return new Promise((resolve, reject) => {
      this.firestore.collection('users', ref => ref.where('admissionNo', '==', no)).get()
        .forEach(res => {
          if (res.docs && res.docs.length > 0) {
            for (let doc = 0; doc < res.docs.length; doc++) {
              let keyId = res.docs[doc].id;
              let rec = res.docs[doc].data();
              rec.keyId = keyId;
              if (rec.events) {
                resolve(rec);
              } else {
                rec.events = [];
                resolve(rec);
              }
            }
          }
        })
        .catch(err => {
          reject(err);
        });
    });
    //return student;
  }

  searchData(user, searchTerm, selectedStreams, selectedLevels, selectedCourses, selectedBatches) {
    let userType = "";
    this.latestEntry = null;
    this._userData = new BehaviorSubject([]);
    this.userData = this._userData.asObservable();
    console.log(user);
    if (user === 'allStudents') {
      userType = 'student';
      console.log(selectedStreams);
      console.log(selectedBatches);

      if ((!selectedStreams || selectedStreams.length < 1) && (!selectedLevels || selectedLevels.length < 1)
        && (!selectedCourses || selectedCourses.length < 1) && (!selectedBatches || selectedBatches.length < 1)) {
        //NO FILTER SELECTED
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('firstName', '==', searchTerm)
        ).valueChanges();
        console.log(searchData);
        return searchData;
      } else if ((selectedStreams && selectedStreams.length > 0) && (!selectedLevels || selectedLevels.length < 1)
        && (!selectedCourses || selectedCourses.length < 1) && (!selectedBatches || selectedBatches.length < 1)) {
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('streamId', 'in', selectedStreams)
          .where('firstName', '==', searchTerm)).valueChanges();
        console.log(searchData);
        return searchData;

      } else if ((selectedStreams && selectedStreams.length > 0) && (selectedLevels && selectedLevels.length > 0)
        && (!selectedCourses || selectedCourses.length < 1) && (!selectedBatches || selectedBatches.length < 1)) {
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('levelId', 'in', selectedLevels)
          .where('firstName', '==', searchTerm)).valueChanges();
        console.log(searchData);
        return searchData;

      } else if ((selectedStreams && selectedStreams.length > 0) && (selectedLevels && selectedLevels.length > 0)
        && (selectedCourses && selectedCourses.length > 0) && (!selectedBatches || selectedBatches.length < 1)) {
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('courseId', 'in', selectedCourses)
          .where('firstName', '==', searchTerm)).valueChanges();
        console.log(searchData);
        return searchData;

      } else if ((selectedStreams && selectedStreams.length > 0) && (selectedLevels && selectedLevels.length > 0)
        && (selectedCourses && selectedCourses.length > 0) && (selectedBatches && selectedBatches.length > 0)) {
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('batchId', 'in', selectedBatches)
          .where('firstName', '==', searchTerm)).valueChanges();
        console.log(searchData);
        return searchData;
      }


      // if (selectedStreams && selectedStreams.length > 0) {
      //   if (selectedBatches && selectedBatches.length > 0) {
      //     //BOTH STREAM AND BATCH ARE SELECTED
      //     let searchData = this.firestore.collection('users', ref => ref
      //       .where('userType', '==', userType)
      //       .where('stream', 'in', selectedStreams)
      //       .where('firstName', '==', searchTerm)).valueChanges();
      //     console.log(searchData);
      //     return searchData;
      //   } else {
      //     //BATCH NOT SELECTED
      //     let searchData = this.firestore.collection('users', ref => ref
      //       .where('userType', '==', userType)
      //       .where('stream', 'in', selectedStreams)
      //       .where('firstName', '==', searchTerm)).valueChanges();
      //     console.log(searchData);
      //     return searchData;
      //   }
      // } else {
      //   if (selectedBatches && selectedBatches.length > 0) {
      //     //ONLY BATCH IS SELECTED
      //     let searchData = this.firestore.collection('users', ref => ref
      //       .where('userType', '==', userType)
      //       .where('batch', 'in', selectedBatches)
      //       .where('firstName', '==', searchTerm)).valueChanges();
      //     console.log(searchData);
      //     return searchData;
      //   } else {
      //     //NO FILTER SELECTED
      //     let searchData = this.firestore.collection('users', ref => ref
      //       .where('userType', '==', userType)
      //       .where('firstName', '==', searchTerm)
      //     ).valueChanges();
      //     console.log(searchData);
      //     return searchData;
      //   }
      // }
    } else {
      userType = 'faculty';
      console.log(selectedStreams);
      console.log(selectedLevels);
      if ((selectedStreams && selectedStreams.length > 0) && (selectedLevels && selectedLevels.length > 0)) {
        console.log("Both Designation and Department is selected");
        //Both department and designation selected
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('department', 'in', selectedStreams)
          .where('firstName', '==', searchTerm)).valueChanges();
        //console.log(searchData);
        return searchData;
      } else if ((selectedStreams && selectedStreams.length > 0) && (!selectedLevels || selectedLevels.length < 1)) {
        //console.log("Only Department is selected");
        //Only department is selected
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('department', 'in', selectedStreams)
          .where('firstName', '==', searchTerm)).valueChanges();
        //console.log(searchData);
        return searchData;
      } else if ((!selectedStreams && selectedStreams.length < 1) && (selectedLevels || selectedLevels.length > 0)) {
        console.log("Only Designation is selected");
        //Only designation is selected
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('designation', 'in', selectedLevels)
          .where('firstName', '==', searchTerm)).valueChanges();
        //console.log(searchData);
        return searchData;
      } else {
        //No Designation nor Department is selected
        //console.log("No Designation nor Department is selected");
        let searchData = this.firestore.collection('users', ref => ref
          .where('userType', '==', userType)
          .where('firstName', '==', searchTerm)).valueChanges();
        //console.log(searchData);
        return searchData;
      }
    }


    // if ((!selectedStreams || selectedStreams.length < 1)
    //   && (!selectedBatches || selectedBatches.length < 1)) {

    //   let searchData = this.firestore.collection('users', ref => ref
    //     .where('userType', '==', userType)
    //     .where('firstName', '==', searchTerm)).valueChanges();
    //   console.log(searchData);
    //   return searchData;
    // } else {
    //   if ((selectedStreams || selectedStreams.length > 0)) {
    //     let searchData = this.firestore.collection('users', ref => ref
    //       .where('userType', '==', userType)
    //       .where('stream', 'in', selectedStreams)
    //       .where('firstName', '==', searchTerm)).valueChanges();
    //     console.log(searchData);
    //     return searchData;
    //   } else if ((selectedBatches || selectedBatches.length > 0)) {
    //     let searchData = this.firestore.collection('users', ref => ref
    //       .where('userType', '==', userType)
    //       .where('batch', 'in', selectedBatches)
    //       .where('firstName', '==', searchTerm)).valueChanges();
    //     console.log(searchData);
    //     return searchData;
    //   }
    // }



  }
  read_student_by_email(email) {
    // console.log('student service ' + email);
    // console.log(this.firestore.collection('Students'));
    // return this.firestore.collection('Students', ref => ref.where('userName', '==', email)).snapshotChanges();
    // let student = this.firestore.collection('users', ref => ref.where('userName', '==', email).limit(1)).valueChanges();
    let student = this.firestore.collection('users', ref => ref.where('userName', '==', email)).valueChanges();
    console.log("Value changed for the user..");
    return student;
  }
  update_mobileToken(id, record) {

    this.firestore.doc('users/' + id).update(record);
  }
  update_Student(recordId, record) {
    // let updateValue = {
    //   attendance: record.attendance
    // };
    // console.log(updateValue);
    //console.log(record);
    //console.log(record.keyId + " - " + JSON.stringify(record));
    this.firestore.doc('users/' + recordId).update(record);
  }
  update_record(collection, recordId, recordValue) {
    //console.log(record.keyId + " - " + JSON.stringify(record));
    this.firestore.doc(collection + '/' + recordId).update(recordValue);
  }
  delete_Student(record_id) {
    this.firestore.doc('users/' + record_id).delete();
  }

  read_studentsBatchFullList(collection, userType, batch) {
    //console.log(batch);
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('userType', '==', userType)
        .where('batch', '==', batch)
        .orderBy('firstName', 'asc')).get().forEach(rec => {
          let studArray = [];
          this.retarray = [];
          if (rec.docs) {
            for (let a = 0; a < rec.docs.length; a++) {
              //Define the object with the necessary fields
              // if(rec.docs[a].data().admissionNo === 'Z001'){
              //   studArray.push(rec.docs[a].id);
              // }

              if (rec.docs[a].data().employeeNo === "Z12346") {
                //console.log(rec.docs[a].id);
              }
              //console.log(d);
              if (rec.docs[a].data().batch === batch) {
                this.retarray.push(rec.docs[a].data());
              }

              //Push it to the array to return
              if (a === rec.docs.length - 1) {
                this.retarray.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
                //console.log(this.retarray.length);
                resolve(this.retarray);
              }
            }
          } else {
            this.retarray = [];
            resolve(this.retarray);
          }
        }).catch((error: any) => {
          reject(error);
        });
    });
  }

  // You need to return the doc to get the current cursor.
  getCollection(ref, queryFn?): Observable<any[]> {
    return this.firestore.collection(ref, queryFn).snapshotChanges();
    // map(actions => {
    //   return actions.map(a => {
    //     const data = a.payload.doc.data();
    //     const id = a.payload.doc.id;
    //     const doc = a.payload.doc;
    //     return { id, ...data, doc };
    //   });
    // });
  }

  batch_data_searchFirst(batch) {

    this._userData = new BehaviorSubject([]);
    this.userData = this._userData.asObservable();

    let data_array = [];
    const usersRef = this.getCollection("users", ref => ref
      .where('batch', '==', batch)
      .orderBy('firstName', 'asc')
      .limit(10))
      .subscribe(data => {
        this.latestEntry = data[data.length - 1].payload.doc;
        this._userData.next(data);
      });
    // this.firestore.collection('users', ref => ref.where('userType', '==', 'faculty')).valueChanges();
  }
  batch_data_searchNext(batch) {
    // this.read_user();
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection("users", ref => ref
        .where('batch', '==', batch)
        .orderBy('firstName', 'asc')
        .startAfter(this.latestEntry)
        .limit(10))
        .get().subscribe(result => {
          if (result.docs[0]) {
            this.latestEntry = result.docs[result.docs.length - 1];
            for (let a = 0; a < result.docs.length; a++) {
              this.retarray.push(result.docs[a].data());
              if (a === result.docs.length - 1) {
                resolve(this.retarray);
              }
            }
          } else {
            resolve(this.retarray);
          }
        });
    });
  }
  first(searchTerm, streams, levels, courses, batches) {
    // console.log(searchTerm);
    // console.log(streams);
    // console.log(levels);
    // console.log(courses);
    // console.log(batches);
    this.latestEntry = null;
    //console.log(this.latestEntry);
    this._userData = new BehaviorSubject([]);
    this.userData = this._userData.asObservable();
    let userType = '';
    let batch = '';

    if (searchTerm == 'allFaculties') {

      //console.log(streams);
      //console.log(levels);
      userType = 'faculty';
      if ((streams && streams.length > 0) && (levels && levels.length > 0)) {
        let returnArr = [];
        let returnArray = [];
        //console.log("Department and Designation is choosen.");
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .orderBy('firstName', 'asc')
        )
          .subscribe(data => {
            //console.log(data);
            this.latestDepEntry = data[data.length - 1].payload.doc;
            if (data && data.length > 0) {
              //console.log(returnArr);
              data.forEach((dat, index) => {
                returnArr.push(dat);
                if (index === data.length - 1) {
                  returnArr.forEach((ret, index) => {
                    streams.forEach((dep, index3) => {
                      levels.forEach((des, index4) => {
                        if (ret.payload.doc.data().department === dep && ret.payload.doc.data().designation === des) {
                          returnArray.push(ret);
                        }
                        if (index4 === levels.length - 1) {
                          if (index3 === streams.length - 1) {
                            if (index === returnArr.length - 1) {
                              //console.log(returnArray);

                              this._userData.next(returnArray);
                            }
                          } else {
                            //
                          }
                        } else {
                          //
                        }
                      });
                    });
                  });
                }
              });
            } else {
              //
            }
          });

      } else if (streams && !levels) {
        //console.log("Only department is choosen.");
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('department', 'in', streams)
          .orderBy('designation', 'asc')
          .orderBy('firstName', 'asc')
          //.limit(10)
        )
          .subscribe(data => {
            this.latestEntry = data[data.length - 1].payload.doc;
            this._userData.next(data);
          });
      } else if (!streams && levels) {
        //console.log("Only designation is choosen.");
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('designation', 'in', levels)
          .orderBy('department', 'asc')
          .orderBy('firstName', 'asc')
          //.limit(10)
        )
          .subscribe(data => {
            this.latestEntry = data[data.length - 1].payload.doc;
            this._userData.next(data);
          });
      } else {
        console.log("No designation or department is choosen.");
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .orderBy('department', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10))
          .subscribe(data => {
            this.latestEntry = data[data.length - 1].payload.doc;
            this._userData.next(data);
          });
      }
    }
    else if (searchTerm == 'allStudents') {
      userType = 'student';
      let data_array = [];
      if ((!streams || streams.length < 1) && (!levels || levels.length < 1) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .orderBy('batch', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10))
          .subscribe(data => {
            //console.log(data);
            this.latestEntry = data[data.length - 1].payload.doc;
            this._userData.next(data);
          });
      } else if ((streams && streams.length > 0) && (!levels || levels.length < 1) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
        this.queryData = [];
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('streamId', 'in', streams)
          .orderBy('batch', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10))
          .subscribe(data => {
            this.queryData = data;
            this.latestEntry = data[data.length - 1].payload.doc;
            this._userData.next(this.queryData);
          });

      } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
        this.queryData = [];
        //console.log("Latest ENTRY: " + this.latestEntry);
        // if (this.latestEntry === null) {
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('streamId', 'in', streams)
          //.where('level', '==', lfilter[1])
          .orderBy('batch', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10)
        )
          .subscribe(data => {
            //console.log(data);
            if (data && data.length > 0) {
              this.latestEntry = data[data.length - 1].payload.doc;

              levels.forEach((l, index1) => {
                //console.log(l);
                //let lfilter = l.split(",");
                data.forEach((dat, index) => {
                  if (dat.payload.doc.data().levelId === l) {
                    this.queryData.push(dat);
                  }
                  if (index === data.length - 1 && index1 === levels.length - 1) {
                    //console.log(this.queryData);
                    this._userData.next(this.queryData);
                  }
                });


              });
            } else {
              //
            }
          });
        // } else {
        //   //
        // }

      } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (courses && courses.length > 0) && (!batches || batches.length < 1)) {
        //stream , level, course and batch available
        let coursesList = courses;
        this.queryData = [];
        //console.log("Latest ENTRY: " + this.latestEntry);
        // if (this.latestEntry === null) {
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('streamId', 'in', streams)
          //.where('level', '==', lfilter[1])
          .orderBy('batch', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10)
        )
          .subscribe(data => {
            //console.log(data);
            if (data && data.length > 0) {
              this.latestEntry = data[data.length - 1].payload.doc;

              courses.forEach((c, index1) => {
                //console.log(c);
                //let cfilter = c.split(",");
                data.forEach((dat, index) => {
                  if (dat.payload.doc.data().courseId === c) {
                    this.queryData.push(dat);
                  }
                  if (index === data.length - 1 && index1 === courses.length - 1) {
                    //console.log(this.queryData);
                    this._userData.next(this.queryData);
                  }
                });

              });
            } else {
              //
            }
          });

        // } else {
        //   //
        // }
      } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (courses && courses.length > 0) && (batches && batches.length > 0)) {
        let coursesList = courses;
        this.queryData = [];
        const usersRef = this.getCollection('users', ref => ref
          .where('userType', '==', userType)
          .where('batchId', 'in', batches)
          //.where('level', '==', lfilter[1])
          .orderBy('batch', 'asc')
          .orderBy('firstName', 'asc')
          .limit(10)
        )
          .subscribe(data => {
            if (data && data.length > 0) {
              this.latestEntry = data[data.length - 1].payload.doc;
              streams.forEach((s, index1) => {
                data.forEach((dat, index) => {
                  if (dat.payload.doc.data().streamId === s) {
                    this.queryData.push(dat);
                  }
                  if (index === data.length - 1 && index1 === streams.length - 1) {
                    this._userData.next(this.queryData);
                  }
                });
              });
            } else {
              //
            }
          });
      }
    }
  }

  next(searchTerm, streams, levels, courses, batches) {
    //alert('next is called');
    this._userData = new BehaviorSubject([]);
    this.userData = this._userData.asObservable();
    if (searchTerm) {
      let userType = '';
      let batch = '';
      if (searchTerm == 'allFaculties') {
        //
      }
      else if (searchTerm == 'allStudents') {
        userType = 'student';
        let data_array = [];
        if ((!streams || streams.length < 1) && (!levels || levels.length < 1) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
          const usersRef = this.getCollection('users', ref => ref
            .where('userType', '==', userType)
            .orderBy('batch', 'asc')
            .orderBy('firstName', 'asc')
            .startAfter(this.latestEntry)
            .limit(10))
            .subscribe(data => {
              this.latestEntry = data[data.length - 1].payload.doc;
              this._userData.next(data);
            });
        } else if ((streams && streams.length > 0) && (!levels || levels.length < 1) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
          this.queryData = [];
          const usersRef = this.getCollection('users', ref => ref
            .where('userType', '==', userType)
            .where('streamId', 'in', streams)
            .orderBy('batch', 'asc')
            .orderBy('firstName', 'asc')
            .startAfter(this.latestEntry)
            .limit(10))
            .subscribe(data => {
              this.queryData = data;
              this.latestEntry = data[data.length - 1].payload.doc;
              this._userData.next(this.queryData);
            });

        } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (!courses || courses.length < 1) && (!batches || batches.length < 1)) {
          this.queryData = [];
          const usersRef = this.getCollection('users', ref => ref
            .where('userType', '==', userType)
            .where('streamId', 'in', streams)
            //.where('level', '==', lfilter[1])
            .orderBy('batch', 'asc')
            .orderBy('firstName', 'asc')
            .startAfter(this.latestEntry)
            .limit(10)
          )
            .subscribe(data => {
              if (data && data.length > 0) {
                this.latestEntry = data[data.length - 1].payload.doc;

                levels.forEach((l, index1) => {
                  data.forEach((dat, index) => {
                    if (dat.payload.doc.data().levelId === l) {
                      this.queryData.push(dat);
                    }
                    if (index === data.length - 1 && index1 === levels.length - 1) {
                      this._userData.next(this.queryData);
                    }
                  });
                });
              } else {
                //
              }
            });

        } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (courses && courses.length > 0) && (!batches || batches.length < 1)) {
          //stream , level, course and batch available
          let coursesList = courses;
          this.queryData = [];
          const usersRef = this.getCollection('users', ref => ref
            .where('userType', '==', userType)
            .where('streamId', 'in', streams)
            //.where('level', '==', lfilter[1])
            .orderBy('batch', 'asc')
            .orderBy('firstName', 'asc')
            .startAfter(this.latestEntry)
            .limit(10)
          )
            .subscribe(data => {
              if (data && data.length > 0) {
                this.latestEntry = data[data.length - 1].payload.doc;

                courses.forEach((c, index1) => {
                  data.forEach((dat, index) => {
                    if (dat.payload.doc.data().courseId === c) {
                      this.queryData.push(dat);
                    }
                    if (index === data.length - 1 && index1 === courses.length - 1) {
                      this._userData.next(this.queryData);
                    }
                  });

                });
              } else {
                //
              }
            });
        } else if ((streams && streams.length > 0) && (levels && levels.length > 0) && (courses && courses.length > 0) && (batches && batches.length > 0)) {
          let coursesList = courses;
          this.queryData = [];
          const usersRef = this.getCollection('users', ref => ref
            .where('userType', '==', userType)
            .where('batchId', 'in', batches)
            //.where('level', '==', lfilter[1])
            .orderBy('batch', 'asc')
            .orderBy('firstName', 'asc')
            .startAfter(this.latestEntry)
            .limit(10)
          )
            .subscribe(data => {
              if (data && data.length > 0) {
                this.latestEntry = data[data.length - 1].payload.doc;
                streams.forEach((s, index1) => {
                  data.forEach((dat, index) => {
                    if (dat.payload.doc.data().streamId === s) {
                      this.queryData.push(dat);
                    }
                    if (index === data.length - 1 && index1 === streams.length - 1) {
                      this._userData.next(this.queryData);
                    }
                  });
                });
              } else {
                //
              }
            });
        }

      }
    } else {
      this.queryData = [];
      this._userData.next(this.queryData);
    }
  }

  readUserData(searchString): Observable<any[]> {
    if (searchString === "allFaculties") {
      return this.firestore.collection('users', ref => ref.where('userType', '==', 'faculty')).valueChanges();
    }
    else {
      return this.firestore.collection('users', ref => ref.where('userType', '==', 'student')).valueChanges();
    }

  }

  read_menteeList(collection, id) {
    // this.read_user();
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('userType', '==', 'student')
        .where('mentor.employeeNo', '==', id)
        .orderBy('firstName', 'asc'))
        .get().subscribe(result => {

          if (result.docs[0]) {
            for (let a = 0; a < result.docs.length; a++) {
              this.retarray.push(result.docs[a].data());
              if (a === result.docs.length - 1) {
                resolve(this.retarray);
              }
            }
          } else {
            resolve(this.retarray);
          }

        });
    });
  }

  read_scheduleList(collection, department) {
    // this.read_user();
    //console.log(collection + " - " + id);
    console.log(department);
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('isDeleted', '==', "false")
        .orderBy('eventDate', 'asc'))
        .get().subscribe(result => {
          if (result.docs[0]) {
            for (let a = 0; a < result.docs.length; a++) {
              if(result.docs[a].data().events){
                for (let s = 0; s < result.docs[a].data().events.length; s++) {
                  if(result.docs[a].data().events.organizingDepartment && result.docs[a].data().events.organizingDepartment.length>0){
                    result.docs[a].data().events.organizingDepartment.forEach((od,index) => {
                      if (od.departmentName === department) {
                        this.retarray.push(result.docs[a].data().events[s]);
                      }
                    });
                  }
                  
                  if (a === result.docs.length - 1) {
                    if (s === result.docs[a].data().events.length - 1) {
                      resolve(this.retarray);
                    }
                  }
                }
              }else {
                //
              }              
            }
          } else {
            resolve(this.retarray);
          }

        });
    });
  }
  read_curriculumScheduleList(collection, mnth) {
    
    // this.read_user();
    //console.log(collection + " - " + id);
    let month = moment(mnth).format("YYYY-MM");
    console.log(month);
    //console.log(month);
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('isDeleted', '==', "false")
        .orderBy('eventDate', 'asc'))
        .get().subscribe(result => {
          if (result.docs[0]) {
            for (let a = 0; a < result.docs.length; a++) {
              if(result.docs[a].data().events && result.docs[a].data().events.length>0){
                for (let s = 0; s < result.docs[a].data().events.length; s++) {
                  if (moment(result.docs[a].data().events[s].eventStartTime).format("YYYY-MM") === month) {
                    this.retarray.push(result.docs[a].data().events[s]);
                  }
                  if (a === result.docs.length - 1) {
                    if (s === result.docs[a].data().events.length - 1) {
                      console.log(this.retarray);
                      resolve(this.retarray);
                    }
                  }
                }
              }
              
            }
          } else {
            resolve(this.retarray);
          }

        });
    });
  }
  readMentees(employeeNo) {
    //this.student.employeeNo === this.menteeList[a].mentor['employeeNo']
    return this.firestore.collection('users', ref => ref.where('employeeNo', '==', employeeNo)).get();
  }
  readEmpName(employeeName) {
    return this.firestore.collection('users', ref => ref.where('firstName', '==', employeeName)).get();
  }
  read_studentsData(searchTerm) {
    //pRazv6VqNmJrxeDu4yAX

    return new Promise((resolve, reject) => {
      if (searchTerm === "allFaculties") {
        this.firestore.collection('users', ref => ref.where('userType', '==', 'faculty')).get().forEach(rec => {
          let studArray = [];
          this.retarray = [];
          for (let a = 0; a < rec.docs.length; a++) {
            //Define the object with the necessary fields
            if (rec.docs[a].data().firstName && rec.docs[a].data().lastName && rec.docs[a].data().employeeNo) {
              if (rec.docs[a].data().employeeNo === 'Z12346') {
              }

              let d = {
                firstName: rec.docs[a].data().firstName,
                lastName: rec.docs[a].data().lastName,
                employeeNo: rec.docs[a].data().employeeNo,
                avatar: rec.docs[a].data().avatar,
                email: rec.docs[a].data().email,
                gender: rec.docs[a].data().gender,
                designation: rec.docs[a].data().designation,
                department: rec.docs[a].data().department,
                userType: rec.docs[a].data().userType
              }
              // if(d.admissionNo === "Z001"){
              //   console.log(rec.docs[a].data().id);
              // }
              //console.log(d);
              this.retarray.push(d);
            }
            //Push it to the array to return
            if (a === rec.docs.length - 1) {
              this.retarray.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
              //console.log(this.retarray.length);
              resolve(this.retarray);
            }
          }
        }).catch((error: any) => {
          reject(error);
        });
      } else {
        this.firestore.collection('users', ref => ref.where('userType', '==', 'student')).get().forEach(rec => {
          let studArray = [];
          this.retarray = [];
          if (rec.docs) {
            for (let a = 0; a < rec.docs.length; a++) {
              //Define the object with the necessary fields

              if (searchTerm === "allStudents") {
                if (rec.docs[a].data().firstName && rec.docs[a].data().lastName && rec.docs[a].data().admissionNo) {
                  // if(rec.docs[a].data().admissionNo === 'Z001'){
                  //   studArray.push(rec.docs[a].id);
                  // }
                  let d = {
                    firstName: rec.docs[a].data().firstName,
                    lastName: rec.docs[a].data().lastName,
                    admissionNo: rec.docs[a].data().admissionNo,
                    avatar: rec.docs[a].data().avatar,
                    email: rec.docs[a].data().email,
                    gender: rec.docs[a].data().gender,
                    batch: rec.docs[a].data().batch,
                    stream_name: rec.docs[a].data().stream_name,
                    level: rec.docs[a].data().level,
                    userType: rec.docs[a].data().userType,
                    mentor: rec.docs[a].data().mentor
                  }
                  // if(d.admissionNo === "Z001"){
                  //   console.log(rec.docs[a].data().id);
                  // }
                  //console.log(d);
                  this.retarray.push(d);
                }
              } else {

                if (rec.docs[a].data() && rec.docs[a].data().batch) {
                  if (rec.docs[a].data().firstName && rec.docs[a].data().lastName && rec.docs[a].data().admissionNo && rec.docs[a].data().batch === searchTerm) {
                    // if(rec.docs[a].data().admissionNo === 'Z001'){
                    //   studArray.push(rec.docs[a].id);
                    // }
                    let d = {
                      firstName: rec.docs[a].data().firstName,
                      lastName: rec.docs[a].data().lastName,
                      admissionNo: rec.docs[a].data().admissionNo,
                      avatar: rec.docs[a].data().avatar,
                      email: rec.docs[a].data().email,
                      gender: rec.docs[a].data().gender,
                      batch: rec.docs[a].data().batch,
                      stream_name: rec.docs[a].data().stream_name,
                      level: rec.docs[a].data().level
                    }
                    // if(d.admissionNo === "Z001"){
                    //   console.log(rec.docs[a].data().id);
                    // }
                    //console.log(d);
                    this.retarray.push(d);
                  }
                } else {
                  //
                }

              }
              //Push it to the array to return
              if (a === rec.docs.length - 1) {
                this.retarray.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
                //console.log(this.retarray.length);
                resolve(this.retarray);
              }
            }
          } else {
            this.retarray = [];
            resolve(this.retarray);
          }

        }).catch((error: any) => {
          reject(error);
        });
      }

    });

  }
  read_notifications(collection, user, id) {
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref.where('userType', '==', user)
        .orderBy('Date', 'desc'))
        .get().subscribe(result => {
          for (let a = 0; a < result.docs.length; a++) {
            for (let b = 0; b < result.docs[a].data().recipients.length; b++) {
              if (result.docs[a].data().recipients[b] === id) {
                let id = result.docs[a].id;
                let res = result.docs[a].data();
                res.keyId = id;
                res.Date = new Date(res.Date).toISOString();
                //Define the object with the necessary fields
                setTimeout(() => {
                  if (res.isDeleted === 'false') {
                    //Push it to the array to return
                    this.retarray.push(res);
                    //this.retarray = this.retarray.sort((a, b) => moment(b.Date).unix() - moment(a.Date).unix());
                  }
                }, 100);
              } else {
                //
              }
            }
            if (a === result.docs.length - 1) {
              //console.log(this.retarray.length);
              resolve(this.retarray);
            }
          }
        });
    });
  }
  get_subject_byId(pid) {
    console.log(pid);
    let subjectretarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection("studentAcademic", ref => ref
        .where('id', 'in', pid)
      )
        .get().subscribe(phaseresult => {
          if (phaseresult.docs.length > 0) {
            phaseresult.docs.forEach((ph, index) => {
              subjectretarray.push(ph.data());
              if (index === phaseresult.docs.length - 1) {
                resolve(subjectretarray);
              }
            });
          } else {
            //
          }

        });
    });
  }
  get_Location_byId(lid) {
    console.log(lid);
    let locationretarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection("eventLocation", ref => ref
        .where('id', 'in', lid)
      )
        .get().subscribe(locationresult => {
          if (locationresult.docs.length > 0) {
            locationresult.docs.forEach((loc, index) => {
              locationretarray.push(loc.data());
              if (index === locationresult.docs.length - 1) {
                resolve(locationretarray);
              }
            });
          } else {
            //
          }

        });
    });
  }
  read_Locations(collection, stream, level, course) {
    let locationretarray = [];
    console.log(stream);
    console.log(level);
    console.log(course);
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('streamId', 'in', stream)
        .orderBy('name', 'asc')
      )
        .get().subscribe(locationresult => {
          for (let a = 0; a < locationresult.docs.length; a++) {
            let locationid = locationresult.docs[a].id;
            let locationres = locationresult.docs[a].data();
            locationres.keyId = locationid;
            //Define the object with the necessary fields
            if (locationres.isDeleted === 'false') {
              level.forEach((l, index) => {
                course.forEach((c, index1) => {
                  // batch.forEach((b, index2) => {
                  if (locationres.levelId === l && locationres.courseId === c) {
                    //Push it to the array to return
                    locationretarray.push(locationres);
                    //console.log(groupretarray);
                  }
                  if (index === level.length - 1 && index1 === course.length - 1 && a === locationresult.docs.length - 1) {
                    locationretarray.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    //console.log(this.retarray.length);
                    resolve(locationretarray);
                  }
                  // });
                });
              });
            } else {
              //
            }
          }
        });

    });
  }
  read_Faculties(collection, faculties) {
    let facultyretarray = [];
    console.log(faculties);
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('id', 'in', faculties)
        .orderBy('name', 'asc')
      )
        .get().subscribe(facultyresult => {
          if (facultyresult && facultyresult.docs.length > 0) {
            for (let a = 0; a < facultyresult.docs.length; a++) {
              let facultyid = facultyresult.docs[a].id;
              let facultyres = facultyresult.docs[a].data();
              facultyres.keyId = facultyid;
              //Define the object with the necessary fields
              if (facultyres.isDeleted === 'false') {
                facultyretarray.push(facultyres);
              } else {
                //
              }
              if (a === facultyresult.docs.length - 1) {
                resolve(facultyretarray);
              }
            }
          } else {
            resolve(facultyretarray);
          }
        });
    });
  }
  read_scheduleGroups(collection, stream, level, course, batch) {
    let groupretarray = [];
    console.log(stream);
    console.log(level);
    console.log(course);
    console.log(batch);
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('streamId', 'in', stream)
        .orderBy('groupId', 'asc')
      )
        .get().subscribe(groupresult => {
          for (let a = 0; a < groupresult.docs.length; a++) {
            let groupid = groupresult.docs[a].id;
            let groupres = groupresult.docs[a].data();
            groupres.keyId = groupid;
            //Define the object with the necessary fields
            if (groupres.isDeleted === 'false') {
              level.forEach((l, index) => {
                course.forEach((c, index1) => {
                  batch.forEach((b, index2) => {
                    if (groupres.levelId === l && groupres.courseId === c && groupres.batchId === b) {
                      //Push it to the array to return
                      groupretarray.push(groupres);
                      //console.log(groupretarray);
                    }
                    if (index === level.length - 1 && index1 === course.length - 1 && index2 === batch.length - 1 && a === groupresult.docs.length - 1) {
                      groupretarray.sort((a, b) => (a.name > b.name) ? 1 : -1);
                      //console.log(this.retarray.length);
                      resolve(groupretarray);
                    }
                  });
                });
              });
            } else {
              //
            }
          }
        });

    });
  }

  read_phaseSubject_newEvent(collection, stream, level, course) {
    let phaseretarray = [];
    //console.log(stream);    
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref
        .where('streamId', 'in', stream)
        .orderBy('name', 'asc')
      )
        .get().subscribe(phaseresult => {
          if (phaseresult.docs && phaseresult.docs.length > 0) {
            //console.log(phaseresult.docs);
            for (let a = 0; a < phaseresult.docs.length; a++) {
              let phaseid = phaseresult.docs[a].id;
              let phaseres = phaseresult.docs[a].data();
              phaseres.keyId = phaseid;
              //Define the object with the necessary fields
              if (phaseres.isDeleted === 'false') {
                level.forEach((l, index) => {
                  //console.log(phaseres.levelId+":"+level);
                  course.forEach((c, index1) => {
                    //console.log(phaseres.courseId+":"+course);
                    //batch.forEach((b, index2) => {                      
                    //console.log(phaseres.batchId+":"+batch);
                    if (phaseres.levelId === l && phaseres.courseId === c) {
                      //Push it to the array to return
                      phaseretarray.push(phaseres);
                      //console.log(phaseretarray);
                    }
                    if (index === level.length - 1 && index1 === course.length - 1 && a === phaseresult.docs.length - 1) {
                      phaseretarray.sort((a, b) => (a.name > b.name) ? 1 : -1);
                      //console.log(this.retarray.length);
                      resolve(phaseretarray);
                    }
                    //});
                  });
                });
              } else {
                //
              }
            }
          }
        });
    });
  }

  read_Units(collection) {
    let unitretarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection)
        .get().subscribe(unitresult => {
          for (let a = 0; a < unitresult.docs.length; a++) {

            let unitid = unitresult.docs[a].id;
            let unitres = unitresult.docs[a].data();
            unitres.keyId = unitid;
            //Define the object with the necessary fields
            setTimeout(() => {
              if (unitres.isDeleted === 'false') {
                //Push it to the array to return
                unitretarray.push(unitres);
              }
              if (a === unitresult.docs.length - 1) {
                unitretarray.sort((a, b) => (a.name > b.name) ? 1 : -1);
                //console.log(this.retarray.length);
                resolve(unitretarray);
              }
            }, 100);
          }

        });

    });
  }
  read_Data(collection) {
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection)
        .get().subscribe(result => {
          for (let a = 0; a < result.docs.length; a++) {
            let id = result.docs[a].id;
            let res = result.docs[a].data();
            //  console.log(id+"-"+res.employeeNo+"="+res.userName);
            res.keyId = id;
            //Define the object with the necessary fields
            setTimeout(() => {
              if (res.isDeleted === 'false') {
                //Push it to the array to return
                this.retarray.push(res);
              }
              if (a === result.docs.length - 1) {
                this.retarray.sort((a, b) => (a.researchId > b.researchId) ? 1 : -1);
                //console.log(this.retarray.length);
                resolve(this.retarray);
              }
            }, 100);
          }

        });

    });
  }
  read_Groups(collection) {
    this.retarray = [];
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection)
        .get().subscribe(result => {
          for (let a = 0; a < result.docs.length; a++) {
            let id = result.docs[a].id;
            let res = result.docs[a].data();
            //  console.log(id+"-"+res.employeeNo+"="+res.userName);
            res.keyId = id;
            //Define the object with the necessary fields
            setTimeout(() => {
              if (res.isDeleted === 'false') {
                //Push it to the array to return
                this.retarray.push(res);
              }
              if (a === result.docs.length - 1) {
                this.retarray.sort((a, b) => (a.groupId > b.groupId) ? 1 : -1);
                //console.log(this.retarray.length);
                resolve(this.retarray);
              }
            }, 100);
          }

        });

    });
  }
  group_data(groupId) {
    console.log(groupId);
    return new Promise((resolve, reject) => {
      this.firestore.collection('groups', ref => ref.where('id', '==', groupId)).get().subscribe(rec => {
        let studArray = [];
        this.retarray = [];
        if (rec.docs && rec.docs.length > 0) {
          for (let a = 0; a < rec.docs.length; a++) {
            if (rec.docs[a].data().isDeleted === "false") {
              this.retarray.push(rec.docs[a].data());
            }
            if (a === rec.docs.length - 1) {
              this.retarray.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
              resolve(this.retarray);
            }
          }
        } else {
          this.retarray = [];
          resolve(this.retarray);
        }

      });

    });
  }
  update_data(collection, record) {
    this.firestore.doc(collection + '/' + record.keyId).update(record);
  }
  find_studentAdmissionNo(collection, studentAdmissionNo) {
    //alert(studentAdmissionNo);
    return new Promise((resolve, reject) => {
      this.firestore.collection(collection, ref => ref.where('admissionNo', '==', studentAdmissionNo)).get().subscribe(rec => {
        this.retarray = [];
        if (rec.docs) {
          //console.log(rec.docs);
          //alert(rec.docs.length);
          for (let a = 0; a < rec.docs.length; a++) {
            let record = rec.docs[a];
            let studId = record.id;
            let stud = record.data();
            //alert(studId);
            //console.log(studentAdmissionNo+" - "+JSON.stringify(rec.docs[a].data()));
            stud.keyId = studId;
            setTimeout(() => {
              resolve(stud);
            }, 100);
          }
        } else {
          this.retarray = [];
          reject("Error fetching Data. " + studentAdmissionNo);
        }

      });

    });
  }

  get_event_attendance(eid, edate) {
    //console.log(eid + " - " + edate);
    let attendance = this.firestore.collection('eventAttendanceDetails', ref => ref
      .where('eventId', '==', eid)
      .where('eventDate', '==', edate)
      .limit(1)).snapshotChanges();
    //console.log(attendance);
    return attendance;
  }
  get_event_details(eid) {
    //console.log(eid);
    let event = this.firestore.collection('schedule', ref => ref
      .where('eventId', '==', eid)
      .limit(1)).snapshotChanges();
    //console.log(event);
    return event;
  }
  get_event_detailsDate(eid, edate) {
    //console.log(edate);
    let data = {};
    return new Promise((resolve, reject) => {
      this.firestore.collection('schedule', ref => ref
        .where('date', '==', edate))
        .valueChanges().forEach(res => {
          if (res && res.length > 0) {
            for (let a = 0; a < res.length; a++) {
              //console.log(res[a]);
              resolve(res[a]);
            }
          } else {
            resolve(data);
          }
        });
    });
  }

  fetchAppVersion() {
    return new Promise((resolve, reject) => {
      this.firestore.collection('appversion', ref => ref)
        .get().subscribe(versionresult => {
          resolve(versionresult.docs[0].data().versionCode);
        });

    });
  }

  deleteUsersProd() {
    return new Promise((resolve, reject) => {
      this.firestore.collection('users', ref => ref
      .where('batch', '==', '2018')
      .where('gender', '==', 'female'))
        .get().subscribe(versionresult => {
          //resolve(versionresult.docs[0].data().versionCode);
          // console.log(versionresult.docs.length);
          // for(let a = 0; a < versionresult.docs.length; a++){
          //   console.log(a+" : "+versionresult.docs[a].data().keyId+" : "+versionresult.docs[a].data().admissionNo);
            // this.firestore.collection('users').doc(versionresult.docs[a].data().keyId).delete()
            // .then(res => {
            //   console.log(versionresult.docs[a].data().keyId+" Deleted.");
            // })
          // }
          
        });

    });
  }

}
