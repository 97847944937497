import { Component, OnInit, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss'],
})
export class BillDetailsComponent implements OnInit {

  @Input('bill-details') billdetails: any;

  constructor(
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {}

  async showBillDetails(billdetails: any) {
    let toast = await this.toastCtrl.create({
      message: `Added to cart: ${billdetails.name}`,
      duration: 2000
    });
    toast.present();
  }

}
