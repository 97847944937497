import { Component } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { Platform, MenuController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Storage } from "@ionic/storage";
import { StudentService } from "./services/student.service";
import { MsalService } from "@azure/msal-angular";
import { Subscription } from "rxjs";
import { AuthService } from "./services/auth.service";

import { subCollectionServie } from "./services/subCollection.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  showSubmenu: boolean = false;
  showMBBSmenu: boolean = false;
  public appPages = [];
  public appPagesadmin = [
    {
      title: "Home",
      url: "/home",
      searchTerm: "",
      icon: "home",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Schedule",
      url: "/schedule",
      searchTerm: "",
      icon: "calendar",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "People",
      url: "/people",
      searchTerm: "allStudents",
      icon: "contacts",
      colsize: 4,
      cardcolor: "tertiary",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"],
      subPages: [
        { title: 'All Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
        // { title: 'MBBS', url: '/mbbs', icon: "contacts",
        //   mbbsPages: [
        //     { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
        //     { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
        //     { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
        //     { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
        //     { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
        //     { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
        //     { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
        //   ],
        // },
        { title: 'Faculty', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
      ]
    },
    {
      title: "Bills",
      url: "/list",
      searchTerm: "",
      icon: "filing",
      colsize: 4,
      cardcolor: "warning",
      onhome: true,
      roles: ["student"],
    },
    {
      title: "My Mentees",
      url: "/my-mentees",
      searchTerm: "",
      icon: "contacts",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["admin", "faculty"]
    },
    {
      title: "Academic Details",
      url: "/attendance-marks",
      searchTerm: "",
      icon: "book",
      colsize: 4,
      cardcolor: "primary",
      onhome: true,
      roles: ["student"]
    },
    // {
    //   title: "Attendance",
    //   url: "/attendance",
    //   searchTerm: "",
    //   icon: "people",
    //   colsize: 4,
    //   cardcolor: "primary",
    //   onhome: true,
    // },
    {
      title: "Notifications",
      url: "/alerts",
      searchTerm: "",
      icon: "alert",
      colsize: 4,
      cardcolor: "danger",
      onhome: true,
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "UG Research",
      url: "/ugresearch",
      searchTerm: "",
      colsize: 4,
      icon: "bookmarks",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Profile",
      url: "/profile",
      searchTerm: "",
      icon: "person",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
    {
      title: "Logout",
      url: "/logout",
      searchTerm: "",
      icon: "log-out",
      roles: ["admin", "faculty", "student", "secretary", "curriculum secretary"]
    },
  ];
  // public appPagesstudent = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   {
  //     title: "Academics",
  //     url: "/my-academics",
  //     searchTerm: "",
  //     icon: "book",
  //     colsize: 4,
  //     cardcolor: "secondary",
  //     onhome: true,
  //   },
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       // { title: 'MBBS', url: '/mbbs', icon: "contacts",
  //       //   mbbsPages: [
  //       //     { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //       //     { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //       //     { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //       //     { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //       //     { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //       //     { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //       //     { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //       //   ],
  //       // },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "Bills",
  //     url: "/list",
  //     searchTerm: "",
  //     icon: "filing",
  //     colsize: 4,
  //     cardcolor: "warning",
  //     onhome: true,
  //   },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];
  // public appPagesfaculty = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },    
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       // { title: 'MBBS', url: '/mbbs', icon: "contacts",
  //       //   mbbsPages: [
  //       //     { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //       //     { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //       //     { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //       //     { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //       //     { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //       //     { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //       //     { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //       //   ],
  //       // },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "My Mentees",
  //     url: "/my-mentees",
  //     searchTerm: "",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },
  //   // {
  //   //   title: "Attendance",
  //   //   url: "/attendance",
  //   //   searchTerm: "",
  //   //   icon: "people",
  //   //   colsize: 4,
  //   //   cardcolor: "primary",
  //   //   onhome: true,
  //   // },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];
  // public appPagessecretary = [
  //   {
  //     title: "Home",
  //     url: "/home",
  //     searchTerm: "",
  //     icon: "home",
  //   },
  //   {
  //     title: "Schedule",
  //     url: "/schedule",
  //     searchTerm: "",
  //     icon: "calendar",
  //     colsize: 4,
  //     cardcolor: "primary",
  //     onhome: true,
  //   },    
  //   {
  //     title: "People",
  //     url: "/people",
  //     searchTerm: "allStudents",
  //     icon: "contacts",
  //     colsize: 4,
  //     cardcolor: "tertiary",
  //     onhome: true,
  //     subPages: [
  //       { title: 'Students', url: '/allstudents', icon: "contacts", searchTerm: "allStudents" },
  //       // { title: 'MBBS', url: '/mbbs', icon: "contacts",
  //       //   mbbsPages: [
  //       //     { title: '2014 Students', url: 'all2014', icon: "contacts", searchTerm: "2014" },
  //       //     { title: '2015 Students', url: 'all2015', icon: "contacts", searchTerm: "2015" },
  //       //     { title: '2016 Students', url: 'all2016', icon: "contacts", searchTerm: "2016" },
  //       //     { title: '2017 Students', url: 'all2017', icon: "contacts", searchTerm: "2017" },
  //       //     { title: '2018 Students', url: 'all2018', icon: "contacts", searchTerm: "2018" },
  //       //     { title: '2019 Students', url: 'all2019', icon: "contacts", searchTerm: "2019" },
  //       //     { title: '2020 Students', url: 'all2020', icon: "contacts", searchTerm: "2020" }
  //       //   ],
  //       // },
  //       { title: 'Faculties', url: 'allfaculties', icon: "contacts", searchTerm: "allFaculties" }
  //     ]
  //   },
  //   {
  //     title: "Notifications",
  //     url: "/alerts",
  //     searchTerm: "",
  //     icon: "alert",
  //     colsize: 4,
  //     cardcolor: "danger",
  //     onhome: true,
  //   },
  //   {
  //     title: "UG Research",
  //     url: "/ugresearch",
  //     searchTerm: "",
  //     colsize: 4,
  //     icon: "bookmarks",
  //   },
  //   {
  //     title: "Profile",
  //     url: "/profile",
  //     searchTerm: "",
  //     icon: "person",
  //   },
  //   {
  //     title: "Logout",
  //     url: "/logout",
  //     searchTerm: "",
  //     icon: "log-out",
  //   },
  // ];

  student: any;
  loggedUser: any;
  data: any;
  searchEmail: string;
  authPersonChangedSubscription: Subscription;
  menuChangedSubscription: Subscription;
  public authenticated: boolean;
  displayMenu: any = [];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router,
    public menuCtrl: MenuController,
    //public events: Events,
    private studentService: StudentService,
    private authService: AuthService,
    private msalService: MsalService,

    private subcollectionService: subCollectionServie
  ) {
    
    this.storage.set('appPagesadmin', this.appPagesadmin);
    // this.storage.set('appPagesstudent', this.appPagesstudent);
    // this.storage.set('appPagesfaculty', this.appPagesfaculty);
    // this.storage.set('appPagessecretary', this.appPagessecretary);
    this.data = {};
    this.displayMenu = [];
    // if (this.platform.is("android") || this.platform.is("ios")) {
    //   this.storage.get("loggedUser").then(val => {
    //     if (val) {
    //       this.loggedUser = val;
    //       this.student = val;
    //       //SIDE MENU ITEMS FOR THE USER ROLE
    //       this.displayMenu = [];
    //       this.appPagesadmin.forEach((menu, mindex) => {
    //         menu.roles.forEach((roles, mindex1) => {
    //           if (this.student.userRole) {
    //             this.student.userRole.forEach((role, rindex) => {
    //               if (roles === role) {
    //                 this.displayMenu.push(menu);
    //                 //console.log(this.displayMenu);
    //               } else {
    //                 //
    //               }
    //               if (mindex === this.appPagesadmin.length - 1 && mindex1 === menu.roles.length - 1 && rindex === this.student.userRole.length - 1) {
    //                 let newArray = [];
    //                 if (this.displayMenu && this.displayMenu.length > 0) {
    //                   let uniqueObject = {};
    //                   for (let i in this.displayMenu) {
    //                     let objTitle = this.displayMenu[i].title;
    //                     uniqueObject[objTitle] = this.displayMenu[i];
    //                   }
    //                   for (let i in uniqueObject) {
    //                     newArray.push(uniqueObject[i]);
    //                   }
    //                   console.log(newArray);
    //                   this.displayMenu = newArray;
    //                   this.storage.set("displayMenu", this.displayMenu);
    //                 }

    //               }
    //             });
    //           }
    //         });

    //       });
    //       //console.log("student from storage: ", this.student);
    //       this.initializeApp();
    //     } else {
    //       this.initializeApp();
    //     }

    //   }).catch((reason) => {
    //     console.log('Could not find user in local storage');
    //   });
    // } else {
      this.storage.get("loggedUser").then(val => {
        if (val) {
          this.loggedUser = val;
          this.student = val;
          //SIDE MENU ITEMS FOR THE USER ROLE
          this.displayMenu = [];
          this.appPagesadmin.forEach((menu, mindex) => {
            menu.roles.forEach((roles, mindex1) => {
              if (this.student.userRole) {
                this.student.userRole.forEach((role, rindex) => {
                  if (roles === role) {
                    this.displayMenu.push(menu);
                    //console.log(this.displayMenu);
                  } else {
                    //
                  }
                  if (mindex === this.appPagesadmin.length - 1
                    && mindex1 === menu.roles.length - 1
                    && rindex === this.student.userRole.length - 1) {
                    let newArray = [];
                    if (this.displayMenu && this.displayMenu.length > 0) {
                      let uniqueObject = {};
                      for (let i in this.displayMenu) {
                        let objTitle = this.displayMenu[i].title;
                        uniqueObject[objTitle] = this.displayMenu[i];
                      }
                      for (let i in uniqueObject) {
                        newArray.push(uniqueObject[i]);
                      }
                      //console.log(newArray);
                      this.displayMenu = newArray;
                      this.storage.set("displayMenu", this.displayMenu);
                      console.log(this.displayMenu);
                    }
                  }
                });
              }
            });

          });
          //console.log("student from storage: ", this.student);
          this.initializeApp();
        } else {
          this.initializeApp();
        }

      }).catch((reason) => {
        console.log('Could not find user in local storage');
      });

      // if (msalService.getAccount()) {
      //   //this.authenticated = this.msalService.getAccount() != null;
      //   this.authenticated = true;
      //   //console.log("1. Auth service: authenticated", this.authenticated);
      //   this.searchEmail = msalService.getAccount().userName;
      //   //console.log('1. In app.comp:', this.searchEmail);

      //   if (this.searchEmail) {
      //     this.studentService
      //       .read_student_by_email(this.searchEmail)
      //       .subscribe((data) => {
      //         //console.log('1a. In app.comp subscribe ss: ', data[0].payload.doc.data());
      //         //Set Object Value
      //         this.setValue("student", data[0]);
      //         this.setValue("loggedUser", data[0]);
      //         this.student = data[0];
      //         //console.log("home page loaded");
      //       });
      //   }
      // }
    //}

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    // this.events.subscribe('menu', (data) => {      
    //   this.displayMenu = data;
    // });
    
    
    this.authPersonChangedSubscription = this.authService.authPersonC$.subscribe(
      (val) => {
        //console.log("2. In app.comp initApp: authPersonC", val);
        this.student = val;
        //console.log(this.student);
        if(this.displayMenu.length>0){
          console.log(this.displayMenu);
        }else{
          console.log("no menu");
          this.menuChangedSubscription = this.authService.menuChanged$.subscribe((m) => {
            //console.log(m);
            this.displayMenu = m;
            console.log(this.displayMenu);
      
          });
        }
        if (this.student === undefined || !this.student) {
          let url: string = "/login";
          this.router.navigateByUrl(url);
        } else {
          this.loggedUser = this.student;
          //console.log(this.student);
        }
        // if (this.student.userType === "student"){      
        //   this.setValue("appPages", this.appPagesstudent);
        // }
        // else if (this.student.userType === "faculty") {
        //   this.setValue("appPages", this.appPagesfaculty);
        // } else {
        //   console.log("no app pages");
        // }
      }
    );

    //console.log("appPages", this.appPages.length);
    //this.appPages.length > 0) {
    // if (this.loggedUser.userType === "student"){

    //   this.setValue("appPages", this.appPagesstudent);
    // }
    // else if (this.loggedUser.userType === "faculty") {
    //   this.setValue("appPages", this.appPagesfaculty);
    // } else {
    //   console.log("no app pages");
    // }
  }
  menuItemHandler() {

  }
  openPageWithState(url: string, searchTerm: string) {
    //console.log(url);
    if (url === "/people") {
      this.showSubmenu = !this.showSubmenu;
    } else {
      if (url === "/mbbs") {
        this.showMBBSmenu = !this.showMBBSmenu;
      } else {
        console.log("In open page with state");
        //this.student = this.getValue('student');
        let navigationExtras: NavigationExtras = {
          state: {
            student: this.student,
            searchTerm: searchTerm
          },
        };
        this.router.navigate([url], navigationExtras);
        this.showSubmenu = false;
        this.showMBBSmenu = false;
        this.menuCtrl.close();
      }

    }

  }

  menuOpened() {
    console.log("side menu opened");
    this.storage.get("loggedUser").then((val) => {
      //console.log("val", val);
      this.student = val;
    });
  }


  // get a key/value pair
  getValue(key: string) {
    this.storage
      .get(key)
      .then((val) => {
        //console.log("get " + key + " ", val);
        this.data[key] = "";
        this.data[key] = val;
      })
      .catch((error) => {
        console.log("get error for " + key + "", error);
      });
  }

  // set a key/value
  setValue(key: string, value: any) {
    this.storage
      .set(key, value)
      .then((response) => {
        //console.log("set " + key + " ", response);
        //get Value Saved in key
        this.getValue(key);
      })
      .catch((error) => {
        console.log("set error for " + key + " ", error);
      });
  }

  async subCollectionData() {
    // this.subcollectionService.new_SubCollection()
    // .then(result => {
    //   console.log(result);
    // })
    // .catch(err => {
    //   console.log(err);
    // });

    this.subcollectionService.ScheduleinsertStreamLevelCourseBatchStudents()
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        console.log(err);
      });

  }
}
