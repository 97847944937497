export const OAuthSettings = {
  appId: '96ca51b4-458f-45d5-847d-bcb2d31b4670',
  authority: "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1",
  redirectURI: "http://localhost:8100",
  scopes: [
    "openid",
    "profile", 
    "user.Read", 
    "Mail.Send", 
    "Mail.Read", 
    "Calendars.Read"
  ],
  loginHint: "",
  popUp: true
};
