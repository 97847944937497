import { PipesModule } from "./pipes/pipes.module";
import { ComponentsModule } from "./components/components.module";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { MSAdal } from "@ionic-native/ms-adal/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SuperTabsModule } from "@ionic-super-tabs/angular";

import { HttpClientModule } from "@angular/common/http";
import { IonicStorageModule } from "@ionic/storage";
import { File } from '@ionic-native/file/ngx';
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { environment } from '../environments/environment.prod';
//import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MsalModule } from "@azure/msal-angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { HTTP } from "@ionic-native/http/ngx";
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { IonicSelectableModule } from "ionic-selectable";
import { BillDetailsPageModule } from '../app/bill-details/bill-details.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import {
  AngularFireFunctionsModule,
  FUNCTIONS_REGION
} from '@angular/fire/functions';
@NgModule({
  declarations: [AppComponent],

  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SuperTabsModule.forRoot(),
    ComponentsModule,
    PipesModule,
    BillDetailsPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    MsalModule.forRoot({
      // auth: {
      //   clientId: "08d4537e-36e3-42c4-8b64-f8f8e39ad2b7",
      //   authority: "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1",
      //   // redirectUri: "msauth.in.edu.cmcvellore.cap://auth"
      // },
      // cache: {
      //   storeAuthStateInCookie: true
      // }
      auth: {
        clientId: "96ca51b4-458f-45d5-847d-bcb2d31b4670",
        authority: "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1",
        //redirectUri: "http://localhost:8100"
        //redirectUri: "https://test-cap.cmcvellore.edu.in/home"
        redirectUri: "https://cap.cmcvellore.edu.in/home"
      },
      // redirectUri: "http://localhost:8100"
      // redirectUri: "https://cap.cmcvellore.edu.in/home"
      // redirectUri: "https://capp-548b8.firebaseapp.com/home"
      // auth: {
      //   clientId: "96ca51b4-458f-45d5-847d-bcb2d31b4670",
      //   authority: "https://login.microsoftonline.com/4d80bca5-ffd0-416d-9d68-210f85db0ed1",
      //   redirectUri: "https://capp-548b8.firebaseapp.com/home"
      // },
      cache: {
        storeAuthStateInCookie: true,
      },
    }),
    IonicSelectableModule,
  ],
  providers: [
    FirebaseX,
    MSAdal,
    HTTP,
    File,
    InAppBrowser,
    StatusBar,
    SplashScreen,
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    ImagePicker
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//         redirectUri: "msauth.in.edu.cmcvellore.cap://auth"
//         redirectUri: "http://localhost:8100/home"
// https://login.microsoftonline.com/common/oauth2/nativeclient
// msal08d4537e-36e3-42c4-8b64-f8f8e39ad2b7://auth
