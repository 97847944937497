export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB6irXm5DmQ9IMctGa_eP2V6ppnMkPg-xo",
    authDomain: "capp-548b8.firebaseapp.com",
    databaseURL: "https://capp-548b8.firebaseio.com",
    projectId: "capp-548b8",
    storageBucket: "capp-548b8.appspot.com",
    messagingSenderId: "315863413271",
    appId: "1:315863413271:web:80e272a1da22e4b618a252"
   }
};
